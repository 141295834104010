import './Testimonial.css';

const Testimonial = ({ title, subtitle, name, position, image, alt_text }) => {

    return (
        <div className="w-full flex justify-center z-40">
            <div className="w-full md:w-10/12 flex flex-row">
                <div className="testimonial-apostrophe">“</div>
                <div className="w-full flex flex-col gap-2 md:gap-4 pt-10 md:pt-24">
                    <h6 className="h6 dark">{title}</h6>
                    <p className="body grey">{subtitle}</p>

                    <div className="flex items-center gap-2 md:gap-4 pt-2 md:pt-4 w-max testimonial-name-border">
                        <img src={image} alt={alt_text} className="w-8 md:w-16 rounded-full"></img>
                        <div>
                            <p className="body dark">{name}</p>
                            <p className='caption grey'>{position}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Testimonial;