// banner
export const HOMETOWN_HEROES_BANNER_TITLE = 'Hometown Heroes Community';
export const HOMETOWN_HEROES_BANNER_SUBTITLE = 'Conceptualizing and designing a community platform to facilitate the career growth of 3000+ local musicians and artists in the Delaware Valley';
export const HOMETOWN_HEROES_BANNER_ALT = 'An illustration showcasing the home page of the Hometown Heroes Community website';

//overview section
export const HOMETOWN_HEROES_OVERVIEW_TITLE1 = 'My Role';
export const HOMETOWN_HEROES_OVERVIEW_SUBTITLE1 = 'Product Designer & Design System Lead';
export const HOMETOWN_HEROES_OVERVIEW_TITLE2 = 'Collaborators';
export const HOMETOWN_HEROES_OVERVIEW_SUBTITLE2 = 'UX Researchers, Product Designers, Product Manager';
export const HOMETOWN_HEROES_OVERVIEW_TITLE3 = 'Project Timeline';
export const HOMETOWN_HEROES_OVERVIEW_SUBTITLE3 = '5 Sprints · Oct 2023 - May 2024';
export const HOMETOWN_HEROES_OVERVIEW_TITLE4 = 'Tools';
export const HOMETOWN_HEROES_OVERVIEW_SUBTITLE4 = 'Figma, FigJam, Google Forms, Notion';

//content sections
export const HOMETOWN_HEROES_SECTION_OVERVIEW_TITLE = 'Overview';
export const HOMETOWN_HEROES_SECTION_OVERVIEW_CONTENT = "Hometown Heroes is a radio show hosted by Mark Rogers showcasing local talent in the Delaware Valley. This project aimed to extend Mark's support for local artists beyond the show's website by exploring various strategies to boost their visibility and help them in their careers.";
export const HOMETOWN_HEROES_SECTION_OVERVIEW_METRIC_NUMBER1 = '3000+';
export const HOMETOWN_HEROES_SECTION_OVERVIEW_METRIC_LABEL1 = 'Musicians Featured';
export const HOMETOWN_HEROES_SECTION_OVERVIEW_METRIC_NUMBER2 = '1000+';
export const HOMETOWN_HEROES_SECTION_OVERVIEW_METRIC_LABEL2 = 'Weekly Listeners';

export const HOMETOWN_HEROES_SECTION_CONTRIBUTIONS_TITLE = 'Contributions';
export const HOMETOWN_HEROES_SECTION_CONTRIBUTIONS_BULLET_NUMBER1 = '01';
export const HOMETOWN_HEROES_SECTION_CONTRIBUTIONS_BULLET_SUBTITLE1 = 'Spearheaded the project launch, driving the team towards solving key user challenges and ensuring a successful outcome.';
export const HOMETOWN_HEROES_SECTION_CONTRIBUTIONS_BULLET_NUMBER2 = '02';
export const HOMETOWN_HEROES_SECTION_CONTRIBUTIONS_BULLET_SUBTITLE2 = 'Led the creation and implementation of a foundational design system to ensure consistency and scalability.';
export const HOMETOWN_HEROES_SECTION_CONTRIBUTIONS_BULLET_NUMBER3 = '03';
export const HOMETOWN_HEROES_SECTION_CONTRIBUTIONS_BULLET_SUBTITLE3 = 'Worked closely with stakeholders to iteratively refine product strategy, balancing user needs with business goals.';

export const HOMETOWN_HEROES_SECTION_CHALLENGE_TITLE = 'Challenge';
export const HOMETOWN_HEROES_SECTION_CHALLENGE_METRIC_NUMBER = 'How might we';
export const HOMETOWN_HEROES_SECTION_CHALLENGE_METRIC_LABEL = 'Empower local artists in the Delaware Valley to navigate the music industry by leveraging existing network and trust associated with Hometown Heroes, and create a for-profit business?';

export const HOMETOWN_HEROES_SECTION_SOLUTION_TITLE = 'Solution';
export const HOMETOWN_HEROES_SECTION_SOLUTION_METRIC_NUMBER = 'Hometown Heroes Community';
export const HOMETOWN_HEROES_SECTION_SOLUTION_METRIC_LABEL = 'A digital community platform for artists to learn from mentors, find gigs and opportunities, and build network';

export const HOMETOWN_HEROES_SECTION_IMPACT_TITLE = 'Impact';
export const HOMETOWN_HEROES_SECTION_IMPACT_METRIC_NUMBER = 'Over 90%';
export const HOMETOWN_HEROES_SECTION_IMPACT_METRIC_LABEL = 'User Satisfaction Rate';
export const HOMETOWN_HEROES_SECTION_IMPACT_CONTENT = 'We achieved a user satisfaction rate of over 90% in the final usability testing of the platform, indicating strong approval for product value and its overall user experience.';

export const HOMETOWN_HEROES_SECTION_PROCESS_TITLE = 'The Process';

export const HOMETOWN_HEROES_SECTION_RESEARCH_LABEL='Discovery Research';
export const HOMETOWN_HEROES_SECTION_RESEARCH_TITLE='Understanding the current scenario';
export const HOMETOWN_HEROES_SECTION_RESEARCH_METRIC_NUMBER1 = '5';
export const HOMETOWN_HEROES_SECTION_RESEARCH_METRIC_LABEL1 = 'Interviews';
export const HOMETOWN_HEROES_SECTION_RESEARCH_METRIC_NUMBER2 = '44';
export const HOMETOWN_HEROES_SECTION_RESEARCH_METRIC_LABEL2 = 'Survey Responses';
export const HOMETOWN_HEROES_SECTION_RESEARCH_METRIC_NUMBER3 = '32';
export const HOMETOWN_HEROES_SECTION_RESEARCH_METRIC_LABEL3 = 'Platform Reviews';
export const HOMETOWN_HEROES_SECTION_RESEARCH_FINDINGS_TITLE1 = 'Upcoming artists struggle to find proper guidance ';
export const HOMETOWN_HEROES_SECTION_RESEARCH_FINDINGS_SUBTITLE1 = 'Young and upcoming artists face difficulties in finding proper guidance and gaining visibility.';
export const HOMETOWN_HEROES_SECTION_RESEARCH_FINDINGS_TITLE2 = 'Networking and trust is crucial for career growth';
export const HOMETOWN_HEROES_SECTION_RESEARCH_FINDINGS_SUBTITLE2 = 'Artists struggle to connect with suitable managers, venues, and collaborators, facing challenges in securing trustworthy opportunities.';
export const HOMETOWN_HEROES_SECTION_RESEARCH_FINDINGS_TITLE3 = 'Artists are lookout for income streams';
export const HOMETOWN_HEROES_SECTION_RESEARCH_FINDINGS_SUBTITLE3 = 'Artists lack a stable income stream, relying predominantly on gigs, royalties, and merch sales, with minimal earnings from streaming platforms.';

export const HOMETOWN_HEROES_SECTION_PRIORITIZATION_LABEL='Prioritization';
export const HOMETOWN_HEROES_SECTION_PRIORITIZATION_TITLE='Focus on connections & mentorship';
export const HOMETOWN_HEROES_SECTION_PRIORITIZATION_ALT='An image of FigJam sticky notes depicting HMWs and possible directions for the solution, with the chosen one as highlighted';

export const HOMETOWN_HEROES_SECTION_USER_FLOWS_LABEL='User Flows';
export const HOMETOWN_HEROES_SECTION_USER_FLOWS_TITLE='Conceptualizing the mentorship platform';
export const HOMETOWN_HEROES_SECTION_USER_FLOWS_ALT='A user flow diagram of a mentee trying to get mentorship';

export const HOMETOWN_HEROES_SECTION_CONCEPT_TESTING_LABEL='Concept Testing';
export const HOMETOWN_HEROES_SECTION_CONCEPT_TESTING_TITLE='What did we find out?';
export const HOMETOWN_HEROES_SECTION_CONCEPT_TESTING_ALT='Low-fidelity wireframes used in the concept testing';
export const HOMETOWN_HEROES_SECTION_CONCEPT_TESTING_CONTENT='Concept testing of these wireframes with users validated our idea, and gave us key insights.';
export const HOMETOWN_HEROES_SECTION_CONCEPT_TESTING_FINDINGS_TITLE1='Copyright awareness is highly important';
export const HOMETOWN_HEROES_SECTION_CONCEPT_TESTING_FINDINGS_SUBTITLE1='Added a disclaimer emphasizing the importance of copyright for shared music.';
export const HOMETOWN_HEROES_SECTION_CONCEPT_TESTING_FINDINGS_TITLE2='Mentor recognition boosts morale';
export const HOMETOWN_HEROES_SECTION_CONCEPT_TESTING_FINDINGS_SUBTITLE2='Created a value based review system that benefits both the mentors as well as mentees.';
export const HOMETOWN_HEROES_SECTION_CONCEPT_TESTING_FINDINGS_TITLE3='Revision of mentorship categories';
export const HOMETOWN_HEROES_SECTION_CONCEPT_TESTING_FINDINGS_SUBTITLE3='Updated the list of mentorship categories to best suit the needs of artists.';

export const HOMETOWN_HEROES_SECTION_PRODUCT_STRATEGY_LABEL='Product Strategy';
export const HOMETOWN_HEROES_SECTION_PRODUCT_STRATEGY_TITLE='How do we monetize this product?';
export const HOMETOWN_HEROES_SECTION_PRODUCT_STRATEGY_CONTENT_TITLE1='Mentors offer 10 free sessions before charging for paid sessions';
export const HOMETOWN_HEROES_SECTION_PRODUCT_STRATEGY_CONTENT_SUBTITLE1='Free sessions can be used by struggling artists that are tight on money, and gives a chance to mentor to build their credibility before they start charging.';
export const HOMETOWN_HEROES_SECTION_PRODUCT_STRATEGY_CONTENT_TITLE2='Business obtains commission cut for paid mentorship sessions';
export const HOMETOWN_HEROES_SECTION_PRODUCT_STRATEGY_CONTENT_SUBTITLE2='Nominal fee can be charged by the business, while the majority is earned by the mentor for each session.';

export const HOMETOWN_HEROES_SECTION_CONSTRAINT_LABEL='Monetization Constraint';
export const HOMETOWN_HEROES_SECTION_CONSTRAINT_TITLE='Avoid charging struggling artists';

export const HOMETOWN_HEROES_SECTION_RESOLUTION_LABEL='Constraint Resolution';
export const HOMETOWN_HEROES_SECTION_RESOLUTION_TITLE='Conceptualizing the gig finder';
export const HOMETOWN_HEROES_SECTION_RESOLUTION_ALT='A user flow diagram of an artist trying to find a gig';

export const HOMETOWN_HEROES_SECTION_DESIGN_SYSTEM_LABEL='Design System';
export const HOMETOWN_HEROES_SECTION_DESIGN_SYSTEM_TITLE='Modern building blocks';
export const HOMETOWN_HEROES_SECTION_DESIGN_SYSTEM_ALT='A snapshot of token structure, button component, nav bar item component, and input field component';

export const HOMETOWN_HEROES_SECTION_FINAL_DESIGN_TITLE='Final design';
export const HOMETOWN_HEROES_SECTION_FINAL_DESIGN_MENTORSHIP_TITLE='Mentorship';
export const HOMETOWN_HEROES_SECTION_FINAL_DESIGN_MENTORSHIP_SUBTITLE='Connect with the right mentors for guidance and networking';
export const HOMETOWN_HEROES_SECTION_FINAL_DESIGN_GIG_FINDER_TITLE='Gig finder';
export const HOMETOWN_HEROES_SECTION_FINAL_DESIGN_GIG_FINDER_SUBTITLE='Apply to gigs and opportunities for additional income';
export const HOMETOWN_HEROES_SECTION_FINAL_DESIGN_TRACKING_AND_MANAGEMENT_TITLE='Tracking and management';
export const HOMETOWN_HEROES_SECTION_FINAL_DESIGN_TRACKING_AND_MANAGEMENT_SUBTITLE='Track and manage all the sessions and gigs in one place';

export const HOMETOWN_HEROES_SECTION_FUTURE_SCOPE_TITLE='Future scope';
export const HOMETOWN_HEROES_SECTION_FUTURE_SCOPE_TITLE1='Development & launch';
export const HOMETOWN_HEROES_SECTION_FUTURE_SCOPE_SUBTITLE1='Immediate next steps involve development of this solution and making the platform live.';
export const HOMETOWN_HEROES_SECTION_FUTURE_SCOPE_TITLE2='Post-launch data analysis';
export const HOMETOWN_HEROES_SECTION_FUTURE_SCOPE_SUBTITLE2='Analysis of user behavior and interaction patterns on the platform to strategize and refine revenue streams.';
export const HOMETOWN_HEROES_SECTION_FUTURE_SCOPE_TITLE3='Mobile app';
export const HOMETOWN_HEROES_SECTION_FUTURE_SCOPE_SUBTITLE3='Developing a mobile app for allowing users to access the platform on-the-go.';

export const HOMETOWN_HEROES_SECTION_LEARNINGS_TITLE='Learnings';
export const HOMETOWN_HEROES_SECTION_LEARNINGS_NUMBER1='01';
export const HOMETOWN_HEROES_SECTION_LEARNINGS_TITLE1='Early testing provides clarity';
export const HOMETOWN_HEROES_SECTION_LEARNINGS_SUBTITLE1='Testing the concept early without perfecting the solution proved beneficial as we got validation for our ideas and also identified the issues.';
export const HOMETOWN_HEROES_SECTION_LEARNINGS_NUMBER2='02';
export const HOMETOWN_HEROES_SECTION_LEARNINGS_TITLE2='Attention to detail is super important';
export const HOMETOWN_HEROES_SECTION_LEARNINGS_SUBTITLE2='We created a product from scratch, and paying attention to every single detail aided in navigating the complexities.';
export const HOMETOWN_HEROES_SECTION_LEARNINGS_NUMBER3='03';
export const HOMETOWN_HEROES_SECTION_LEARNINGS_TITLE3='Client engagement is a key piece of the puzzle';
export const HOMETOWN_HEROES_SECTION_LEARNINGS_SUBTITLE3='Our client became more engaged when we integrated business and financial considerations into the project, leading to constructive discussions. ';



