import './UnorderedList.css';

const UnorderedList = ({ list , left_padding }) => {

    if (!list) {
        list = [];
    }

    var leftPaddingClass = '';
    if (left_padding) {
        leftPaddingClass = 'pl-12 md:pl-16';
    }

    var unorderedListElements = [];

    var keyID = 0;

    list.forEach((list_item) => {
        unorderedListElements.push(<li className='body grey' key={keyID.toString()}>{list_item}</li>);
        keyID++;
    });

    var unorderedListClass = 'unordered-list-container ' + leftPaddingClass;

    const unorderedListElementsToRender = unorderedListElements.length ? (
        <ul className={unorderedListClass}>
            {unorderedListElements}
        </ul>
    ) : '';

    return (
        <div className='w-full'>
            {unorderedListElementsToRender}
        </div>
    );
}

export default UnorderedList;