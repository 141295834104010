import Card from './Card';
import './CardsGrid.css';

const CardsGrid = ({cards_array, grid_size}) => {

    if (!cards_array) {
        cards_array = [];
    }

    if (!grid_size) {
        grid_size = '4';
    }

    var cardsToRender = [];

    cards_array.forEach((card) => {
        cardsToRender.push(
            <Card
                image={card.image}
                alt_text={card.alt_text}
                label={card.label}
                path={card.path}
            />
        );
    });

    var gridClassName = 'w-full grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-8';
    if (grid_size === '2') {
        gridClassName = 'w-full grid grid-cols-2 gap-4 md:gap-8';
    }

    return (
        <div className={gridClassName}>
            {cardsToRender}
        </div>
    );

}

export default CardsGrid;