import OverviewItem from './OverviewItem';
import './OverviewSection.css';

function OverviewSection({ item_list }) {
    if (!item_list) {
        item_list = [];
    }
    
    var list = [];
    item_list && item_list.length > 0 && item_list.forEach(item => {

        list.push(
            <OverviewItem 
                title={item.title} 
                subtitle={item.subtitle} 
            />
            );
    });

    return (
        <div className="overview-section-container">
            {list}
        </div>
    );
}

export default OverviewSection;
