import './Card.css';

const Card = ({ image, alt_text, label, path }) => {

    if (!image) {
        image = '';
    }
    if (!alt_text) {
        alt_text = '';
    }
    if (!label) {
        label = '';
    }
    
    // if path is undefined then the link is not clickable
    // do not insert an empty path

    // if (!path) {
    //     path = '';
    // }

    return (

        <a href={path} className='card-container' target='_blank'>
            <img src={image} alt={alt_text} className='rounded-xl' />
            <p className='body grey'>{label}</p>
        </a>
    );
}

export default Card;