import React, { useState, useEffect } from 'react';

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Show button when user scrolls down
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Scroll the window to the top smoothly
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <div className="scroll-to-top">
      {isVisible && (
        <button onClick={scrollToTop} style={styles.button}>
          ↑
        </button>
      )}
    </div>
  );
};

// CSS Styles
const styles = {
  button: {
    position: 'fixed',
    bottom: '40px',
    right: '40px',
    padding: '20px',
    fontSize: '18px',
    borderRadius: '500px',
    border: 'none',
    backgroundColor: 'var(--light)',
    color: 'var(--dark)',
    cursor: 'pointer',
    boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
  },
};

export default ScrollToTop;
