import CardsGrid from './CardsGrid';
import './ContentContainer.css';
import Metric from './Metric';
import TextLink from './TextLink';
import UnorderedList from './UnorderedList';
import UserQuote from './UserQuote';
import TextEqualWidth from './TextEqualWidth';
import BulletList from './BulletList';
import TestimonialsCarousel from './TestimonialsCarousel';

const ContentContainer = ({ content_array }) => {

    if (!content_array) {
        content_array = [];
    }

    const colSpanClasses = {
        '0': 'hidden',
        '3': 'md:col-span-3',
        '4': 'md:col-span-4',
        '6': 'md:col-span-6',
        '8': 'md:col-span-8',
        '9': 'md:col-span-9',
        '12': 'md:col-span-12'
    };


    var contentToRender = [];

    content_array.forEach((item) => {

        if (item.content_type === 'text') {
            if (item.text && item.text.length) {
                const textToRender = <p className='body grey'>{item.text}</p>;
                if (item.complexTextFlag) {
                    contentToRender.push(
                        <div className='w-full'>
                        <div className='overall-content-container'>
                            {textToRender}
                        </div>

                    </div>


                    );
                }
                else {
                contentToRender.push(

                    <div className='w-full grid grid-cols-1 md:grid-cols-12 md:gap-16'>
                        <div className='hidden md:block md:col-span-1'></div>
                        <div className='md:col-span-10 overall-content-container'>
                            {textToRender}
                        </div>
                        <div className='hidden md:block md:col-span-1'></div>

                    </div>
                );
                }
            }
        }

        // else if (item.content_type === 'text_5col_5col') {
        //     if (item.text_array && item.text_array.length) {
        //         contentToRender.push(
        //             <div className='w-full grid grid-cols-1 gap-4 md:grid-cols-10 md:gap-16'>
        //             <div className='md:col-span-5 text-content-container'>
        //                 <h6 className='h6 dark'>{item.text_array[0].title}</h6>
        //                 <p className='body grey'>{item.text_array[0].subtitle}</p>
        //             </div>
        //             <div className='md:col-span-5 text-content-container'>
        //                 <h6 className='h6 dark'>{item.text_array[1].title}</h6>
        //                 <p className='body grey'>{item.text_array[1].subtitle}</p>
        //             </div>
        //         </div>    
        //         );
        //     }
        // }

        else if (item.content_type === 'text_4col_8col') {
            if (item.text_array && item.text_array.length) {

                contentToRender.push(
                    <div className='w-full grid grid-cols-1 gap-4 md:grid-cols-12 md:gap-16'>
                        <div className='md:col-span-4 text-content-container'>
                            <h6 className='h6 dark'>{item.text_array[0].title}</h6>
                            <UnorderedList list={item.text_array[0].list} />
                        </div>
                        <div className='md:col-span-8 text-content-container'>
                            <h6 className='h6 dark'>{item.text_array[1].title}</h6>
                            <UnorderedList list={item.text_array[1].list} />
                        </div>
                    </div>
                );
            }
        }


        else if (item.content_type === 'text_equal_width') {
            if (item.text_array && item.text_array.length) {

                if (item.complexTextFlag) {

                    contentToRender.push(
                        <div className='w-full'>
                        <div className='overall-content-container'>
                            <TextEqualWidth text_array={item.text_array} icon_text_alignment={item.icon_text_alignment} />
                        </div>

                    </div>


                    );

                }
                else {
                contentToRender.push(

                    <div className='w-full grid grid-cols-1 md:grid-cols-12 md:gap-16'>
                        <div className='hidden md:block md:col-span-1'></div>
                        <div className='md:col-span-10 overall-content-container'>
                            <TextEqualWidth text_array={item.text_array} icon_text_alignment={item.icon_text_alignment} />
                        </div>
                        <div className='hidden md:block md:col-span-1'></div>

                    </div>


                );
                }
            }
        }

        else if (item.content_type === 'metrics') {
            if (item.metrics_array && item.metrics_array.length) {
                var metricsToRender = [];
                item.metrics_array.forEach((metric) => {
                    metricsToRender.push(
                        <Metric
                            number={metric.number}
                            label={metric.label}
                        />
                    );
                });
                const metricsContainerToRender = (
                    // <div className='w-full grid grid-cols-2 gap-4 md:grid-cols-10 md:gap-16'>
                    <div className='w-full flex flex-row justify-center gap-8 md:gap-32'>
                        {/* <div className='hidden md:block md:col-span-2'></div> */}
                        {/* <div className='col-span-1 md:col-span-3'> */}
                        {/* <div>
                            <Metric
                                number={item.metrics_array[0].number}
                                label={item.metrics_array[0].label}
                            />
                        </div> */}
                        {/* <div className='col-span-1 md:col-span-3'> */}
                        {/* <div>
                            <Metric
                                number={item.metrics_array[1].number}
                                label={item.metrics_array[1].label}
                            />
                        </div> */}
                        {/* <div className='hidden md:block md:col-span-2'></div> */}
                        {metricsToRender}

                    </div>

                );

                if (item.complexTextFlag) {
                    contentToRender.push(
                        <div className='w-full'>
                        <div className='overall-content-container'>
                            {metricsContainerToRender}
                        </div>

                    </div>


                    );
                }
                else {
                contentToRender.push(

                    <div className='w-full grid grid-cols-1 md:grid-cols-12 md:gap-16'>
                        <div className='hidden md:block md:col-span-1'></div>
                        <div className='md:col-span-10 overall-content-container'>
                            {metricsContainerToRender}
                        </div>
                        <div className='hidden md:block md:col-span-1'></div>

                    </div>


                );
                }
            }
        }

        else if (item.content_type === 'bullet_points') {
            if (item.bullet_points && item.bullet_points.length) {

                contentToRender.push(

                    <div className='w-full grid grid-cols-1 md:grid-cols-12 md:gap-16'>
                        <div className='hidden md:block md:col-span-1'></div>
                        <div className='md:col-span-10 overall-content-container'>
                            <BulletList bullet_points={item.bullet_points} />
                        </div>
                        <div className='hidden md:block md:col-span-1'></div>

                    </div>


                );

            }
        }

        else if (item.content_type === 'link') {
            if (item.link_label && item.link_path) {
                const linkToRender = (
                    <TextLink
                        label={item.link_label}
                        path={item.link_path}
                        target={item.link_target}
                    />


                );
                contentToRender.push(

                    <div className='w-full grid grid-cols-1 md:grid-cols-12 md:gap-16'>
                        <div className='hidden md:block md:col-span-1'></div>
                        <div className='md:col-span-10 overall-content-container'>
                            {linkToRender}
                        </div>
                        <div className='hidden md:block md:col-span-1'></div>

                    </div>

                );
            }
        }

        else if (item.content_type === 'user_quotes') {
            if (item.user_quotes && item.user_quotes.length) {
                var userQuotesToRender = [];
                item.user_quotes.forEach((user_quote) => {
                    userQuotesToRender.push(
                        <div className='w-full grid grid-cols-1 gap-0 md:grid-cols-10 md:gap-16 md:justify-center md:items-center'>
                            <div className='md:col-span-5 md:flex md:flex-col md:gap-2'>
                                <h6 className='h6 dark'>{user_quote.title}</h6>
                                <p className='body grey'>{user_quote.subtitle}</p>
                            </div>
                            <div className='md:col-span-5'>
                                <UserQuote quote={user_quote.quote} />
                            </div>
                        </div>
                    );
                });
                contentToRender.push(
                    <div className='w-full grid grid-cols-1 md:grid-cols-12 md:gap-16'>
                        <div className='hidden md:block md:col-span-1'></div>
                        <div className='md:col-span-10 overall-content-container'>
                            {userQuotesToRender}
                        </div>
                        <div className='hidden md:block md:col-span-1'></div>

                    </div>

                );
            }
        }

        else if (item.content_type === 'image') {
            if (item.image) {
                if (!item.alt_text) {
                    item.alt_text = '';
                }
                if (!item.content) {
                    item.content = '';
                }
                if ((!item.image_columns) || (!item.content_columns)) {
                    item.image_columns = '6';
                    item.content_columns = '6';
                }

                const textToRender = (item.content) ? <p className='body grey'>{item.content}</p> : '';
                const bulletPointsToRender = (item.bullet_points) ? <BulletList bullet_points={item.bullet_points} /> : '';

                const complexTextToRender = (item.complexText) ? <ContentContainer 
                    content_array={item.complexText}
                /> : '';

                const orderClass = (item.image_placement === 'right') ? ' md:order-last' : ' md:order-first' ;

                contentToRender.push(
                    <div className='w-full grid grid-cols-1 gap-8 md:grid-cols-12 md:gap-16 md:items-center'>
                        <div className={colSpanClasses[item.image_columns] + orderClass}>
                            <img src={item.image} alt={item.alt_text} />
                        </div>
                        <div className={colSpanClasses[item.content_columns] + ' overall-content-container'}>
                            {/* <p className='body grey'>{item.content}</p> */}
                            {textToRender}
                            {bulletPointsToRender}
                            {complexTextToRender}
                        </div>
                    </div>

                );

            }
        }

        else if (item.content_type === 'cards_grid') {
            contentToRender.push(
                <div className='w-full'>
                    <CardsGrid cards_array={item.cards_array} grid_size={item.grid_size} />
                </div>
            );
        }

        else if (item.content_type === 'subsection') {

            const titleToRender = item.title ? <h4 className='h4 dark text-center'>{item.title}</h4> : '';
            const subtitleToRender = item.subtitle ? <h6 className='h6 dark text-center'>{item.subtitle}</h6> : '';

            const subsectionPaddingClass = item.paddingTop ? 'pt-16' : '';
            const subsectionContainerClass = 'w-full grid grid-cols-1 md:grid-cols-12 md:gap-16 ' + subsectionPaddingClass;
            contentToRender.push(

                <div className={subsectionContainerClass}>
                    <div className='hidden md:block md:col-span-1'></div>
                    <div className='md:col-span-10 subsection-container'>
                        {titleToRender}
                        {subtitleToRender}
                    </div>
                    <div className='hidden md:block md:col-span-1'></div>

                </div>
            );

        }

        else if (item.content_type === 'testimonials') {
            contentToRender.push(
                <div className='w-full'>
                    <TestimonialsCarousel testimonials_array={item.testimonials_array} />
                </div>

            );
        }

        else if (item.content_type === 'video') {
            contentToRender.push(
                <div className='video-container'>
                    <video src={item.video} type='video/mp4' autoPlay loop muted/>
                </div>
            );
        }

    });


    return (
        <div className='w-full overall-content-container'>
            {/* <div className='hidden md:block md:col-span-1'></div> */}
            {/* <div className='md:col-span-10 overall-content-container'> */}
            {contentToRender}
            {/* </div> */}
            {/* <div className='hidden md:block md:col-span-1'></div> */}

        </div>);

}

export default ContentContainer;
