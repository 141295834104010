import './Header.css';
import Logo from './Logo';
import NavigationLink from './NavigationLink';
import * as globalStrings from '../Strings/GlobalStrings.js';
import * as paths from '../Strings/Paths';
import Resume from '../Documents/resume_shaunak_bhanarkar.pdf';

function Header({selected_link_label}) {
  if (!selected_link_label) {
    selected_link_label = '';
  }
    return (
      <header className="container header-container my-0 mx-auto">
        <Logo/>
        <nav className='nav-container'>
            <NavigationLink 
              label={globalStrings.NAV_WORK_LABEL}
              path={paths.HOMEPAGE_PATH}
              state={selected_link_label === globalStrings.NAV_WORK_LABEL}
              />
            <NavigationLink 
              label={globalStrings.NAV_ABOUT_LABEL}
              path={paths.ABOUT_PATH}
              state={selected_link_label === globalStrings.NAV_ABOUT_LABEL} 
              />
            <NavigationLink 
              label={globalStrings.NAV_RESUME_LABEL}
              path={Resume} 
              state={selected_link_label === globalStrings.NAV_RESUME_LABEL}
              target='_blank'
              />
        </nav>
      </header>
    );
  }
  
export default Header;
  