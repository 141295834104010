import './BulletList.css';
import BulletPoint from './BulletPoint';

const BulletList = ({ bullet_points }) => {

    if (!bullet_points) {
        bullet_points = [];
    }

    var bulletPointsToRender = [];

    bullet_points.forEach((bullet_item) => {
        bulletPointsToRender.push(
            <BulletPoint
                number={bullet_item.number}
                title={bullet_item.title}
                subtitle={bullet_item.subtitle}
                body={bullet_item.body}
                body_padding={bullet_item.body_padding}
            />
        );
    });


    return (
        bulletPointsToRender
    );
}

export default BulletList;