import './Button.css';

const Button = ({label, click_function}) => {
    return (
        <button className='button-container' onClick={click_function}>
            <p className='body light'>{label}</p>
        </button>
    );
}

export default Button;