export const NAV_WORK_LABEL = 'Work';
export const NAV_ABOUT_LABEL = 'About';
export const NAV_RESUME_LABEL = 'Resume';

export const FOOTER_CONNECT_TITLE = "Let's Connect";
export const FOOTER_COPYRIGHT_STATEMENT = '© 2024 Shaunak Bhanarkar · Designed in Figma and developed with React';

export const CONNECT_EMAIL = 'mailto:shaunak.design@gmail.com';
export const CONNECT_LINKEDIN = 'https://www.linkedin.com/in/shaunakbhanarkar/';
export const CONNECT_MEDIUM = 'https://medium.com/@shaunakbhanarkar';
export const CONNECT_FIGMA = 'https://www.figma.com/@shaunakb';
export const CONNECT_DRIBBBLE = 'https://dribbble.com/shaunakbhanarkar';