import './OverviewItem.css';

function OverviewItem({title, subtitle}) {
    if (!title) {
        title = '';
    }
    if (!subtitle) {
        subtitle = '';
    }

    return (
      <div className="overview-item-container">
        <h6 className='h6 dark'>{title}</h6>
        <p className='body grey'>{subtitle}</p>
      </div>
    );
  }
  
export default OverviewItem;
  