import BulletList from './BulletList';
import './TextEqualWidth.css';

const TextEqualWidth = ({ text_array, icon_text_alignment }) => {

    if (!text_array) {
        text_array = [];
    }
    
    const iconTextAlignmentClass = (icon_text_alignment === 'vertical') ? 'flex-col items-start' : 'flex-row items-center md:items-start';
    const iconTextContainerClass = 'tew-icon-with-text-container ' + iconTextAlignmentClass;

    var textBlocksToRender = [];

    text_array.forEach((text_block) => {
        const iconToRender = text_block.icon ? <img src={text_block.icon} alt='' className='tew-icon-size' /> : '';
        const titleToRender = text_block.title ? <h6 className='h6 dark'>{text_block.title}</h6> : '';
        const subtitleToRender = text_block.subtitle ? <p className='body grey'>{text_block.subtitle}</p> : '';
        const bulletListToRender = text_block.bullet_points ? <BulletList bullet_points={text_block.bullet_points} /> : '';

        textBlocksToRender.push(
            <div className={iconTextContainerClass}>
                {iconToRender}
                <div className='tew-text-content-container'>
                    {titleToRender}
                    {subtitleToRender}
                    {bulletListToRender}
                </div>
            </div>
        );
    });
    const textBlockWithContainer = (
        <div className='w-full flex flex-col gap-4 md:flex-row md:gap-16'>
            {textBlocksToRender}
        </div>
    );


    return (
        textBlockWithContainer
    );
}

export default TextEqualWidth;