import './CompetitionsGrid.css';
import ProjectCard from './ProjectCard';
import * as homepageStrings from '../Strings/HomepageStrings.js';
import * as paths from '../Strings/Paths';
import competitionUsabilathonCover from '../Images/Homepage/competition_usabilathon_cover.png';
import competitionInfoChallengeCover from '../Images/Homepage/competition_info_challenge_cover.png';
import competitionMakeathonCover from '../Images/Homepage/competition_makeathon_cover.png';
import competitionProtothonCover from '../Images/Homepage/competition_protothon_cover.png';
import competitionUsabilathon2024Cover from '../Images/Homepage/competition_usabilathon_2024_cover.png';

function CompetitionsGrid() {


    //add prop to projectcard to support h6 title so that competitioncard use case is supported
    return (
        <div className='competitions-grid-container'>


            <div className='competition-container'>
                <ProjectCard
                    image={competitionUsabilathon2024Cover}
                    alt_text={homepageStrings.COMPETITION_USABILATHON2024_ALT}
                    tag1_label={homepageStrings.COMPETITION_USABILATHON2024_TAG1}
                    title={homepageStrings.COMPETITION_USABILATHON2024_TITLE}
                    subtitle={homepageStrings.COMPETITION_USABILATHON2024_SUBTITLE}
                    path={paths.COMPETITION_USABILATHON2024_PATH}
                />

            </div>



            <div className='competition-container'>
                <ProjectCard
                    image={competitionUsabilathonCover}
                    alt_text={homepageStrings.COMPETITION_USABILATHON_ALT}
                    tag1_label={homepageStrings.COMPETITION_USABILATHON_TAG1}
                    title={homepageStrings.COMPETITION_USABILATHON_TITLE}
                    subtitle={homepageStrings.COMPETITION_USABILATHON_SUBTITLE}
                    path={paths.COMPETITION_USABILATHON_PATH}
                />

            </div>

            <div className='competition-container'>
                <ProjectCard
                    image={competitionInfoChallengeCover}
                    alt_text={homepageStrings.COMPETITION_INFO_CHALLENGE_ALT}
                    tag1_label={homepageStrings.COMPETITION_INFO_CHALLENGE_TAG1}
                    title={homepageStrings.COMPETITION_INFO_CHALLENGE_TITLE}
                    subtitle={homepageStrings.COMPETITION_INFO_CHALLENGE_SUBTITLE}
                    path={paths.COMPETITION_INFO_CHALLENGE_PATH}
                />

            </div>


            <div className='competition-container'>
                <ProjectCard
                    image={competitionProtothonCover}
                    alt_text={homepageStrings.COMPETITION_PROTOTHON_ALT}
                    tag1_label={homepageStrings.COMPETITION_PROTOTHON_TAG1}
                    tag2_label={homepageStrings.COMPETITION_PROTOTHON_TAG2}
                    title={homepageStrings.COMPETITION_PROTOTHON_TITLE}
                    subtitle={homepageStrings.COMPETITION_PROTOTHON_SUBTITLE}
                    path={paths.COMPETITION_PROTOTHON_PATH}
                />

            </div>

            <div className='competition-container'>
                <ProjectCard
                    image={competitionMakeathonCover}
                    alt_text={homepageStrings.COMPETITION_MAKEATHON_ALT}
                    tag1_label={homepageStrings.COMPETITION_MAKEATHON_TAG1}
                    tag2_label={homepageStrings.COMPETITION_MAKEATHON_TAG2}
                    title={homepageStrings.COMPETITION_MAKEATHON_TITLE}
                    subtitle={homepageStrings.COMPETITION_MAKEATHON_SUBTITLE}
                    path={paths.COMPETITION_MAKEATHON_PATH}
                />

            </div>




        </div>
    );

}

export default CompetitionsGrid;