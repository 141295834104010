export const PASSWORD_PROTECTION_TITLE = 'Password Protected Page';
export const PASSWORD_PROTECTION_INSTRUCTION = 'Please enter the password to view case study.';
export const PASSWORD_PROTECTION_BUTTON_LABEL = 'View Case Study';

export const PASSWORD_PROTECTION_INPUT_NAME = 'rocket-password-input';
export const PASSWORD_PROTECTION_INPUT_LABEL = 'Password:';
export const PASSWORD_PROTECTION_INPUT_PLACEHOLDER = 'Password';

export const ROCKET_CASE_STUDY_PASSWORD = 'sb&design';

