import './ContentSection.css';
import ContentContainer from "./ContentContainer";
import SectionHeader from "./SectionHeader";
// import ContentWithImage from './ContentWithImage';

const ContentSection = ({ title, type, label, content, image, alt_text, image_columns, content_columns, content_array, special }) => {

    // var contentComponent;
    // if (image) {
    //     contentComponent = (
    //         <ContentWithImage
    //             image={image}
    //             alt_text={alt_text}
    //             content={content}
    //             image_columns={image_columns}
    //             content_columns={content_columns}
    //         />
    //     );
    // }
    // else {
        // contentComponent = (
        //     <ContentContainer
        //         content_array={content_array}
        //     />
        // );

    // }


    const containerClass = (special) ? 'content-section-container special-class' : 'content-section-container';

    return (
        <div className={containerClass}>
            <SectionHeader
                title={title}
                type={type}
                label={label}
            />

            {/* {contentComponent} */}

            <ContentContainer content_array={content_array} />

        </div>);

}

export default ContentSection;
