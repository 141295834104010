import './InputField.css';

const InputField = ({name, label, placeholder, type, handleKeyPress}) => {

    return (
        <div className='input-field-container'>
            <label for={name} className='body grey'>{label}</label>
            <input type={type} id={name} name={name} placeholder={placeholder} onKeyDown={handleKeyPress} className='body dark'/>
        </div>
    );
}

export default InputField;