import Footer from '../../Components/Footer';
import Header from '../../Components/Header';
import ratePlansHero from '../../Images/Rate Plans/rate_plans_hero.png';
import ratePlansOverview from '../../Images/Rate Plans/rate_plans_overview.png';
import ratePlansSolution1 from '../../Images/Rate Plans/rate_plans_solution1.png';
import ratePlansSolution2 from '../../Images/Rate Plans/rate_plans_solution2.png';
import ratePlansSolution3 from '../../Images/Rate Plans/rate_plans_solution3.png';
import ratePlansCompetitiveAnalysis from '../../Images/Rate Plans/rate_plans_competitive_analysis.png';
import ratePlans3StepBooking from '../../Images/Rate Plans/rate_plans_3step_booking.png';
import ratePlansVariantX from '../../Images/Rate Plans/rate_plans_variant_x.png';
import ratePlansVariantY from '../../Images/Rate Plans/rate_plans_variant_y.png';
import ratePlansVariantZ from '../../Images/Rate Plans/rate_plans_variant_z.png';
import ratePlansTesting from '../../Images/Rate Plans/rate_plans_testing.png';
import ratePlansRecording from '../../Videos/Rate Plans/rate_plans_recording.mp4';
import '../CaseStudy.css';
import * as ratePlansStrings from '../../Strings/RatePlansStrings';
import CaseStudyBanner from "../../Components/CaseStudyBanner";
import OverviewSection from "../../Components/OverviewSection";
import ContentSection from '../../Components/ContentSection';
import Metric from '../../Components/Metric';
import SectionHeader from '../../Components/SectionHeader';
import BulletPoint from '../../Components/BulletPoint';
import Picture from '../../Components/Picture';
import VideoComponent from '../../Components/VideoComponent';
import ratePlansFinalDesign from '../../Images/Rate Plans/rate_plans_final_design.png';
import ScrollToTop from '../../Components/ScrollToTop';
import Button from '../../Components/Button';
import { useRef } from 'react';


const RatePlans = () => {
    const finalDesignRef = useRef(null);


    return (
        <div>

            <Header />

            <div className="fade-in page-container">
                <div className="sections-container">


                    <div className='container my-0 mx-auto hero-container'>
                        <CaseStudyBanner
                            title={ratePlansStrings.RATE_PLANS_BANNER_TITLE}
                            subtitle={ratePlansStrings.RATE_PLANS_BANNER_SUBTITLE}
                            image={ratePlansHero}
                            alt_text={ratePlansStrings.RATE_PLANS_BANNER_ALT}
                        />
                        <OverviewSection
                            item_list={[
                                {
                                    'title': ratePlansStrings.RATE_PLANS_OVERVIEW_TITLE1,
                                    'subtitle': ratePlansStrings.RATE_PLANS_OVERVIEW_SUBTITLE1
                                },
                                {
                                    'title': ratePlansStrings.RATE_PLANS_OVERVIEW_TITLE2,
                                    'subtitle': ratePlansStrings.RATE_PLANS_OVERVIEW_SUBTITLE2
                                },
                                {
                                    'title': ratePlansStrings.RATE_PLANS_OVERVIEW_TITLE3,
                                    'subtitle': ratePlansStrings.RATE_PLANS_OVERVIEW_SUBTITLE3
                                },
                                {
                                    'title': ratePlansStrings.RATE_PLANS_OVERVIEW_TITLE4,
                                    'subtitle': ratePlansStrings.RATE_PLANS_OVERVIEW_SUBTITLE4
                                }
                            ]}
                        />

                    </div>

                    <div className="container w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
                        <div className="w-full md:w-4/12">
                            <SectionHeader
                                title={ratePlansStrings.RATE_PLANS_SECTION_OVERVIEW_TITLE}
                                type='short'
                            />
                        </div>
                        <div className="w-full md:w-8/12 flex flex-col gap-4 md:gap-8">
                            <p className="body grey">{ratePlansStrings.RATE_PLANS_SECTION_OVERVIEW_CONTENT1}</p>
                            <div className="w-full flex flex-col gap-4 md:flex-row md:gap-16">
                                <Metric
                                    emoji={'🌏'}
                                    number={ratePlansStrings.RATE_PLANS_SECTION_OVERVIEW_METRIC_NUMBER1}
                                    label={ratePlansStrings.RATE_PLANS_SECTION_OVERVIEW_METRIC_LABEL1}
                                />
                                <Metric
                                    emoji={'🏨'}
                                    number={ratePlansStrings.RATE_PLANS_SECTION_OVERVIEW_METRIC_NUMBER2}
                                    label={ratePlansStrings.RATE_PLANS_SECTION_OVERVIEW_METRIC_LABEL2}
                                />
                                <Metric
                                    emoji={'📱'}
                                    number={ratePlansStrings.RATE_PLANS_SECTION_OVERVIEW_METRIC_NUMBER3}
                                    label={ratePlansStrings.RATE_PLANS_SECTION_OVERVIEW_METRIC_LABEL3}
                                />
                            </div>
                            <p className="body grey">{ratePlansStrings.RATE_PLANS_SECTION_OVERVIEW_CONTENT2}</p>
                        </div>
                    </div>



                    <div className="container w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
                        <div className="w-full md:w-4/12">
                            <SectionHeader
                                title={ratePlansStrings.RATE_PLANS_SECTION_CONTRIBUTIONS_TITLE}
                                type='short'
                            />
                        </div>
                        <div className="w-full md:w-8/12 flex flex-col gap-4 md:gap-8">


                            <BulletPoint
                                number={ratePlansStrings.RATE_PLANS_SECTION_CONTRIBUTIONS_BULLET_NUMBER1}
                                subtitle={ratePlansStrings.RATE_PLANS_SECTION_CONTRIBUTIONS_BULLET_SUBTITLE1}
                            />

                            <BulletPoint
                                number={ratePlansStrings.RATE_PLANS_SECTION_CONTRIBUTIONS_BULLET_NUMBER2}
                                subtitle={ratePlansStrings.RATE_PLANS_SECTION_CONTRIBUTIONS_BULLET_SUBTITLE2}
                            />
                            <BulletPoint
                                number={ratePlansStrings.RATE_PLANS_SECTION_CONTRIBUTIONS_BULLET_NUMBER3}
                                subtitle={ratePlansStrings.RATE_PLANS_SECTION_CONTRIBUTIONS_BULLET_SUBTITLE3}
                            />


                        </div>
                    </div>




                    <div className="container w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
                        <div className="w-full md:w-4/12">
                            <SectionHeader
                                title={ratePlansStrings.RATE_PLANS_SECTION_PROBLEM_TITLE}
                                type='short'
                            />
                        </div>
                        <div className="w-full md:w-8/12 flex flex-col gap-4 md:gap-16">
                            <div className="w-full flex flex-col gap-4 md:gap-8">
                                <BulletPoint
                                    number={ratePlansStrings.RATE_PLANS_SECTION_PROBLEM_NUMBER1}
                                    title={ratePlansStrings.RATE_PLANS_SECTION_PROBLEM_TITLE1}
                                    subtitle={ratePlansStrings.RATE_PLANS_SECTION_PROBLEM_SUBTITLE1}
                                />
                                 <BulletPoint
                                    number={ratePlansStrings.RATE_PLANS_SECTION_PROBLEM_NUMBER2}
                                    title={ratePlansStrings.RATE_PLANS_SECTION_PROBLEM_TITLE2}
                                    subtitle={ratePlansStrings.RATE_PLANS_SECTION_PROBLEM_SUBTITLE2}
                                />
                                 <BulletPoint
                                    number={ratePlansStrings.RATE_PLANS_SECTION_PROBLEM_NUMBER3}
                                    title={ratePlansStrings.RATE_PLANS_SECTION_PROBLEM_TITLE3}
                                    subtitle={ratePlansStrings.RATE_PLANS_SECTION_PROBLEM_SUBTITLE3}
                                />
                            </div>

                        </div>
                    </div>


                    <div className="container w-full flex flex-col gap-4 md:gap-16">
                        <div className="w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
                            <div className="w-full md:w-4/12">
                                <SectionHeader
                                    title={ratePlansStrings.RATE_PLANS_SECTION_SOLUTION_TITLE}
                                    type='short'
                                />
                            </div>
                            <div className="w-full md:w-8/12 flex flex-col gap-4 md:gap-16">
                                <BulletPoint
                                    title={ratePlansStrings.RATE_PLANS_SECTION_SOLUTION_BULLET_TITLE}
                                    subtitle={ratePlansStrings.RATE_PLANS_SECTION_SOLUTION_BULLET_SUBTITLE}
                                />
                            </div>
                        </div>
                        <div className="w-full px-4 md:px-16">
                            <VideoComponent
                                video={ratePlansRecording}
                            />
                        </div>
                    </div>





                    <div className="container w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
                        <div className="w-full md:w-4/12">
                            <SectionHeader
                                title={ratePlansStrings.RATE_PLANS_SECTION_IMPACT_TITLE}
                                type='short'
                            />
                        </div>
                        <div className="w-full md:w-8/12 flex flex-col gap-4 md:gap-8">


                            <Metric
                                emoji={'🚀'}
                                number={ratePlansStrings.RATE_PLANS_SECTION_IMPACT_METRIC_NUMBER}
                                label={ratePlansStrings.RATE_PLANS_SECTION_IMPACT_LABEL}
                            />
                            <p className="body grey">{ratePlansStrings.RATE_PLANS_SECTION_IMPACT_CONTENT}</p>


                        </div>
                    </div>         

                    <hr className="divider" />

                    <div className="container w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
                        <div className="w-full md:w-4/12">
                            <SectionHeader
                                title={ratePlansStrings.RATE_PLANS_SECTION_PROCESS_TITLE}
                                type='wide'
                            />
                        </div>
                        <div className="w-full md:w-8/12 flex flex-col gap-4 md:gap-16">
                            <div className='pt-4 md:pt-0 md:text-end'>
                                <Button
                                    label={'Skip to final design'}
                                    click_function={() => finalDesignRef.current?.scrollIntoView({ behavior: 'smooth' })}
                                />
                            </div>
                        </div>
                    </div>




                    <div className="container w-full flex flex-col gap-4 md:gap-16">
                        <div className="w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
                            <div className="w-full md:w-4/12">
                                <SectionHeader
                                    title={ratePlansStrings.RATE_PLANS_SECTION_ANALYSIS_TITLE}
                                    label={ratePlansStrings.RATE_PLANS_SECTION_ANALYSIS_LABEL}
                                    type='short'
                                />
                            </div>
                            <div className="w-full md:w-8/12 flex flex-col gap-4 md:gap-16">
                                <div className="w-full flex flex-col gap-4 md:gap-8">
                                    <BulletPoint
                                        number={ratePlansStrings.RATE_PLANS_SECTION_ANALYSIS_NUMBER1}
                                        title={ratePlansStrings.RATE_PLANS_SECTION_ANALYSIS_TITLE1}
                                        subtitle={ratePlansStrings.RATE_PLANS_SECTION_ANALYSIS_SUBTITLE1}
                                    />
                                    <BulletPoint
                                        number={ratePlansStrings.RATE_PLANS_SECTION_ANALYSIS_NUMBER2}
                                        title={ratePlansStrings.RATE_PLANS_SECTION_ANALYSIS_TITLE2}
                                        subtitle={ratePlansStrings.RATE_PLANS_SECTION_ANALYSIS_SUBTITLE2}
                                    />
                                    <BulletPoint
                                        number={ratePlansStrings.RATE_PLANS_SECTION_ANALYSIS_NUMBER3}
                                        title={ratePlansStrings.RATE_PLANS_SECTION_ANALYSIS_TITLE3}
                                        subtitle={ratePlansStrings.RATE_PLANS_SECTION_ANALYSIS_SUBTITLE3}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="w-full px-4 md:px-16">
                            <Picture
                                image={ratePlansCompetitiveAnalysis}
                                alt_text={ratePlansStrings.RATE_PLANS_SECTION_ANALYSIS_ALT}
                                rounded={true}
                            />
                        </div>
                    </div>


                    <div className="w-full px-4 md:px-16 py-16 md:py-24 background-dark">
                        <div className='container my-0 mx-auto'>
                        <div className="w-full flex flex-col gap-4 md:gap-16">
                            <div className="w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
                                <div className="w-full md:w-4/12">
                                    <SectionHeader
                                        title={ratePlansStrings.RATE_PLANS_SECTION_CONSTRAINT_TITLE1}
                                        label={ratePlansStrings.RATE_PLANS_SECTION_CONSTRAINT_TITLE}
                                        type='short'
                                        invert={true}
                                    />
                                </div>
                                <div className="w-full md:w-8/12 flex flex-col gap-4 md:gap-16">
                                    <p className='body light'>{ratePlansStrings.RATE_PLANS_SECTION_CONSTRAINT_SUBTITLE1}</p>
                                </div>
                            </div>
                            <div className="w-full px-4 md:px-16">
                                <Picture
                                    image={ratePlans3StepBooking}
                                    alt_text={ratePlansStrings.RATE_PLANS_SECTION_CONSTRAINT_ALT}
                                />
                            </div>
                        </div>
                        </div>
                    </div>


                    <div className="container w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
                        <div className="w-full md:w-4/12">
                            <SectionHeader
                                title={ratePlansStrings.RATE_PLANS_SECTION_USER_GOALS_TITLE}
                                label={ratePlansStrings.RATE_PLANS_SECTION_USER_GOALS_LABEL}
                                type='short'
                            />
                        </div>
                        <div className="w-full md:w-8/12 flex flex-col md:flex-row gap-4 md:gap-16">
                            <BulletPoint
                                title={ratePlansStrings.RATE_PLANS_SECTION_GOALS_TITLE1}
                                subtitle={ratePlansStrings.RATE_PLANS_SECTION_GOALS_SUBTITLE1}
                            />
                            <div className='w-full hidden md:block'></div>
                        </div>
                    </div>


                    <div className="container w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
                        <div className="w-full md:w-4/12">
                            <SectionHeader
                                title={ratePlansStrings.RATE_PLANS_SECTION_BUSINESS_GOALS_TITLE}
                                label={ratePlansStrings.RATE_PLANS_SECTION_BUSINESS_GOALS_LABEL}
                                type='short'
                            />
                        </div>
                        <div className="w-full md:w-8/12 flex flex-col md:flex-row gap-4 md:gap-16">
                            <BulletPoint
                                title={ratePlansStrings.RATE_PLANS_SECTION_GOALS_TITLE2}
                                subtitle={ratePlansStrings.RATE_PLANS_SECTION_GOALS_SUBTITLE2}
                            />
                            <BulletPoint
                                title={ratePlansStrings.RATE_PLANS_SECTION_GOALS_TITLE3}
                                subtitle={ratePlansStrings.RATE_PLANS_SECTION_GOALS_SUBTITLE3}
                            />
                        </div>
                    </div>


                    <div className="container w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
                        <div className="w-full md:w-4/12">
                            <SectionHeader
                                title={ratePlansStrings.RATE_PLANS_SECTION_VARIANTS_TITLE}
                                label={ratePlansStrings.RATE_PLANS_SECTION_VARIANTS_LABEL}
                                type='short'
                            />
                        </div>
                        <div className="w-full md:w-8/12 flex flex-col gap-4 md:gap-16">
                            <div className='w-full flex flex-col gap-2 md:gap-4'>
                                <div>
                                    <h6 className='h6 gray'>{ratePlansStrings.RATE_PLANS_SECTION_VARIANTS_LABEL_X}</h6>
                                    <h4 className='h4 dark'>{ratePlansStrings.RATE_PLANS_SECTION_VARIANTS_TITLE_X}</h4>

                                </div>
                                <Picture
                                    image={ratePlansVariantX}
                                    alt_text={ratePlansStrings.RATE_PLANS_SECTION_VARIANTS_ALT_X}
                                    rounded={true}
                                />
                            </div>
                            <div className='w-full flex flex-col gap-2 md:gap-4'>
                                <div>
                                    <h6 className='h6 gray'>{ratePlansStrings.RATE_PLANS_SECTION_VARIANTS_LABEL_Y}</h6>
                                    <h4 className='h4 dark'>{ratePlansStrings.RATE_PLANS_SECTION_VARIANTS_TITLE_Y}</h4>

                                </div>
                                <Picture
                                    image={ratePlansVariantY}
                                    alt_text={ratePlansStrings.RATE_PLANS_SECTION_VARIANTS_ALT_Y}
                                    rounded={true}
                                />
                            </div>
                            <div className='w-full flex flex-col gap-2 md:gap-4'>
                                <div>
                                    <h6 className='h6 gray'>{ratePlansStrings.RATE_PLANS_SECTION_VARIANTS_LABEL_Z}</h6>
                                    <h4 className='h4 dark'>{ratePlansStrings.RATE_PLANS_SECTION_VARIANTS_TITLE_Z}</h4>

                                </div>
                                <Picture
                                    image={ratePlansVariantZ}
                                    alt_text={ratePlansStrings.RATE_PLANS_SECTION_VARIANTS_ALT_Z}
                                    rounded={true}
                                />
                            </div>
                        </div>
                    </div>



                    <div className="container w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
                        <div className="w-full md:w-4/12">
                            <SectionHeader
                                title='Let representative users decide'
                                label={ratePlansStrings.RATE_PLANS_SECTION_TESTING_TITLE}
                                type='short'
                            />
                        </div>
                        <div className="w-full md:w-8/12 flex flex-col gap-4 md:gap-8">
                            <p className="body grey">{ratePlansStrings.RATE_PLANS_SECTION_TESTING_CONTENT}</p>
                            <div className="w-full flex flex-col gap-4 md:flex-row md:gap-16">
                                <Metric
                                    number={ratePlansStrings.RATE_PLANS_SECTION_TESTING_METRIC_NUMBER1}
                                    label={ratePlansStrings.RATE_PLANS_SECTION_TESTING_METRIC_LABEL1}
                                />
                                <Metric
                                    number={ratePlansStrings.RATE_PLANS_SECTION_TESTING_METRIC_NUMBER2}
                                    label={ratePlansStrings.RATE_PLANS_SECTION_TESTING_METRIC_LABEL2}
                                />
                                <Metric
                                    number={ratePlansStrings.RATE_PLANS_SECTION_TESTING_METRIC_NUMBER3}
                                    label={ratePlansStrings.RATE_PLANS_SECTION_TESTING_METRIC_LABEL3}
                                />
                            </div>
                            <div className='w-full'>
                                <Picture
                                    image={ratePlansTesting}
                                    alt_text={ratePlansStrings.RATE_PLANS_SECTION_TESTING_ALT}
                                    rounded={true}
                                />
                            </div>
                            <BulletPoint
                                title={ratePlansStrings.RATE_PLANS_SECTION_TESTING_TITLE1}
                                subtitle={ratePlansStrings.RATE_PLANS_SECTION_TESTING_SUBTITLE1}
                            />
                        </div>
                    </div>




                    <div className="container w-full flex flex-col gap-4 md:gap-16" ref={finalDesignRef}>
                        <div className="w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
                            <div className="w-full md:w-4/12">
                                <SectionHeader
                                    title={ratePlansStrings.RATE_PLANS_SECTION_FINAL_DESIGN_TITLE}
                                    type='short'
                                />
                            </div>
                        </div>
                        <div className="w-full px-4 md:px-16">
                            <Picture
                                image={ratePlansFinalDesign}
                                alt_text={ratePlansStrings.RATE_PLANS_SECTION_FINAL_DESIGN_ALT}
                                rounded={true}
                            />
                        </div>
                    </div>



                    {/* <ContentSection
                        title={ratePlansStrings.RATE_PLANS_SECTION_NEXT_STEPS_TITLE}
                        type='short'
                        content_array={[
                            {
                                'content_type': 'bullet_points',
                                'bullet_points': [
                                    {
                                        'number': ratePlansStrings.RATE_PLANS_SECTION_NEXT_STEPS_BULLET_NUMBER1,
                                        'subtitle': ratePlansStrings.RATE_PLANS_SECTION_NEXT_STEPS_BULLET_SUBTITLE1
                                    },
                                    {
                                        'number': ratePlansStrings.RATE_PLANS_SECTION_NEXT_STEPS_BULLET_NUMBER2,
                                        'subtitle': ratePlansStrings.RATE_PLANS_SECTION_NEXT_STEPS_BULLET_SUBTITLE2
                                    },
                                    {
                                        'number': ratePlansStrings.RATE_PLANS_SECTION_NEXT_STEPS_BULLET_NUMBER3,
                                        'subtitle': ratePlansStrings.RATE_PLANS_SECTION_NEXT_STEPS_BULLET_SUBTITLE3
                                    },
                                    {
                                        'number': ratePlansStrings.RATE_PLANS_SECTION_NEXT_STEPS_BULLET_NUMBER4,
                                        'subtitle': ratePlansStrings.RATE_PLANS_SECTION_NEXT_STEPS_BULLET_SUBTITLE4
                                    }
                                ]
                            }
                        ]}
                    /> */}

                    <div className="container w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
                        <div className="w-full md:w-4/12">
                            <SectionHeader
                                title={ratePlansStrings.RATE_PLANS_SECTION_LEARNINGS_TITLE}
                                type='short'
                            />
                        </div>
                        <div className="w-full md:w-8/12 flex flex-col gap-4 md:gap-16">
                            <div className="w-full flex flex-col gap-4 md:gap-8">
                                <BulletPoint
                                    number={ratePlansStrings.RATE_PLANS_SECTION_LEARNINGS_BULLET_NUMBER1}
                                    subtitle={ratePlansStrings.RATE_PLANS_SECTION_LEARNINGS_BULLET_SUBTITLE1}
                                />
                                <BulletPoint
                                    number={ratePlansStrings.RATE_PLANS_SECTION_LEARNINGS_BULLET_NUMBER2}
                                    subtitle={ratePlansStrings.RATE_PLANS_SECTION_LEARNINGS_BULLET_SUBTITLE2}
                                />
                                <BulletPoint
                                    number={ratePlansStrings.RATE_PLANS_SECTION_LEARNINGS_BULLET_NUMBER3}
                                    subtitle={ratePlansStrings.RATE_PLANS_SECTION_LEARNINGS_BULLET_SUBTITLE3}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="container w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
                        <div className="w-full md:w-4/12">
                            <SectionHeader
                                title={ratePlansStrings.RATE_PLANS_SECTION_WHAT_IF_TITLE}
                                type='short'
                            />
                        </div>
                        <div className="w-full md:w-8/12 flex flex-col gap-4 md:gap-8">
                            <p className='body grey'>{ratePlansStrings.RATE_PLANS_SECTION_WHAT_IF_CONTENT}</p>
                            <div className="w-full flex flex-col gap-4 md:gap-8">
                                <BulletPoint
                                    number={ratePlansStrings.RATE_PLANS_SECTION_WHAT_IF_BULLET_NUMBER1}
                                    title={ratePlansStrings.RATE_PLANS_SECTION_WHAT_IF_TITLE1}
                                    subtitle={ratePlansStrings.RATE_PLANS_SECTION_WHAT_IF_SUBTITLE1}
                                />
                                <BulletPoint
                                    number={ratePlansStrings.RATE_PLANS_SECTION_WHAT_IF_BULLET_NUMBER2}
                                    title={ratePlansStrings.RATE_PLANS_SECTION_WHAT_IF_TITLE2}
                                    subtitle={ratePlansStrings.RATE_PLANS_SECTION_WHAT_IF_SUBTITLE2}
                                />
                                <BulletPoint
                                    number={ratePlansStrings.RATE_PLANS_SECTION_WHAT_IF_BULLET_NUMBER3}
                                    title={ratePlansStrings.RATE_PLANS_SECTION_WHAT_IF_TITLE3}
                                    subtitle={ratePlansStrings.RATE_PLANS_SECTION_WHAT_IF_SUBTITLE3}
                                />
                            </div>
                        </div>
                    </div>














                </div>

                <Footer />

            </div>


            <ScrollToTop />
        </div>
    );

}

export default RatePlans;