import './NavigationLink.css';
import { Link } from 'react-router-dom';
import { HOMEPAGE_PATH } from '../Strings/Paths';


function NavigationLink({label, path, state, target}) {
  
    if (!label) {
        label = '';
    }
    if (!path) {
      // homepage
      path = HOMEPAGE_PATH;
    }
    if (!state) {
      state = false;
    }
    if (!target) {
      target = '';
    }

    var borderClass = 'navigation-link-unselected';
    if (state) {
      borderClass = 'navigation-link-selected';
    }
    var containerClass = 'navigation-link-container' + ' ' + borderClass; 
    return (
      <Link to={path} target={target}>
        <div className={containerClass}>
          <div className='caption dark'>{label}</div>
        </div>
      </Link>
    );
  }
  
export default NavigationLink;