
// banner
export const ROCKET_CASE_STUDY_BANNER_TITLE = 'Rocket Mortgage Tasks';
export const ROCKET_CASE_STUDY_BANNER_SUBTITLE = 'Helping users focus on the right information to avoid delaying their mortgage closing date';
export const ROCKET_CASE_STUDY_BANNER_ALT = 'An illustration of redesigned Rocket Mortgage Origination tasks page';

// overview section
export const ROCKET_CASE_STUDY_OVERVIEW_TITLE1 = 'My Role';
export const ROCKET_CASE_STUDY_OVERVIEW_SUBTITLE1 = 'Experience Designer';

export const ROCKET_CASE_STUDY_OVERVIEW_TITLE2 = 'Collaborators';
export const ROCKET_CASE_STUDY_OVERVIEW_SUBTITLE2 = "Sr. Experience Designer, Sr. UX Writer, Sr. Product Manager";

export const ROCKET_CASE_STUDY_OVERVIEW_TITLE3 = 'Project Timeline';
export const ROCKET_CASE_STUDY_OVERVIEW_SUBTITLE3 = '8 Weeks · Jun - Jul 2023';

export const ROCKET_CASE_STUDY_OVERVIEW_TITLE4 = 'Tools';
export const ROCKET_CASE_STUDY_OVERVIEW_SUBTITLE4 = 'Figma, FigJam, Lucidchart, Adobe Analytics, Jira';

// content sections
export const ROCKET_CASE_STUDY_SECTION_OVERVIEW_TITLE = 'Overview';
export const ROCKET_CASE_STUDY_SECTION_OVERVIEW_CONTENT = 'Rocket Mortgage Origination is an authenticated product experience where the users provide documentation and perform tasks to process their mortgage with Rocket Mortgage. The To-do Page displays the required tasks. I improved the To-do Page to encourage users to complete critical tasks on time in order to avoid delaying their mortgage closing date.';

export const ROCKET_CASE_STUDY_SECTION_PROBLEM_TITLE = 'Problem';
export const ROCKET_CASE_STUDY_SECTION_PROBLEM_CONTENT = 'The To-do Page is filled with information and CTAs. It is difficult for users to navigate and complete critical tasks.\nA delay in completing the critical tasks on time affects their mortgage closing date.';
export const ROCKET_CASE_STUDY_SECTION_PROBLEM_ALT = 'An illustration of current Rocket Mortgage Origination tasks page';

export const ROCKET_CASE_STUDY_SECTION_SOLUTION_TITLE = 'Solution';
export const ROCKET_CASE_STUDY_SECTION_SOLUTION_CONTENT = 'A clean design for the To-do Page that displays only necessary information and provides a clear actionable direction for the users.';
export const ROCKET_CASE_STUDY_SECTION_SOLUTION_ALT = 'An illustration of redesigned Rocket Mortgage Origination tasks page with clean UI';

export const ROCKET_CASE_STUDY_SECTION_PROCESS_TITLE = 'The Process';

export const ROCKET_CASE_STUDY_SECTION_REQ_TITLE = 'Initial Project Requirement';
export const ROCKET_CASE_STUDY_SECTION_REQ_CONTENT = 'As a part of the larger effort to transition entire Rocket Mortgage to a new design system, the initial requirement was to design the To-do Page using new components.';
export const ROCKET_CASE_STUDY_SECTION_REQ_ALT = '';

export const ROCKET_CASE_STUDY_SECTION_INITIATIVE_TITLE = 'Initiative to Do Better';
export const ROCKET_CASE_STUDY_SECTION_INITIATIVE_CONTENT_PROACTIVE_TITLE = 'Proactive Initiative';
export const ROCKET_CASE_STUDY_SECTION_INITIATIVE_CONTENT_PROACTIVE_SUBTITLE = 'I proactively took the initiative to improve the To-do Page, in addition to moving it to a new design system.';
export const ROCKET_CASE_STUDY_SECTION_INITIATIVE_CONTENT_COMMUNICATION_TITLE = 'Communication';
export const ROCKET_CASE_STUDY_SECTION_INITIATIVE_CONTENT_COMMUNICATION_SUBTITLE = 'By discussing the possibility of increasing the scope of project with my team, I was able to get acceptance for improving the To-do Page.';

export const ROCKET_CASE_STUDY_SECTION_WHY_TITLE = 'Why this project?';
export const ROCKET_CASE_STUDY_SECTION_WHY_LABEL= 'Project Motivation';
export const ROCKET_CASE_STUDY_SECTION_WHY_METRIC_VISITS_NUMBER = '65%';
export const ROCKET_CASE_STUDY_SECTION_WHY_METRIC_VISITS_LABEL = 'Visits';
export const ROCKET_CASE_STUDY_SECTION_WHY_METRIC_VISITORS_NUMBER = '73%';
export const ROCKET_CASE_STUDY_SECTION_WHY_METRIC_VISITORS_LABEL = 'Unique Visitors';
export const ROCKET_CASE_STUDY_SECTION_WHY_CONTENT = 'The To-do Page is the most visited page in Rocket Mortgage Origination, and completing critical tasks on time is essential to avoid delaying their mortgage closing date.';

export const ROCKET_CASE_STUDY_SECTION_LACK_TITLE = 'Lack of Data';
export const ROCKET_CASE_STUDY_SECTION_LACK_CONTENT1 = 'To understand the performance of the To-do Page and identify issues with the current design, I tried to gather data and find relevant information. It was challenging, however.';
export const ROCKET_CASE_STUDY_SECTION_LACK_BULLET_NUMBER1 = '01';
export const ROCKET_CASE_STUDY_SECTION_LACK_BULLET_SUBTITLE1 = 'Limited tracking data in analytics software';
export const ROCKET_CASE_STUDY_SECTION_LACK_BULLET_NUMBER2 = '02';
export const ROCKET_CASE_STUDY_SECTION_LACK_BULLET_SUBTITLE2 = 'Metrics relevant to the To-do Page not available';
export const ROCKET_CASE_STUDY_SECTION_LACK_CONTENT2 = 'I proactively reached out to multiple designers, researchers, and product managers to get as much information and data sources as possible.';

export const ROCKET_CASE_STUDY_SECTION_INTERVIEWS_TITLE = 'What did the users say?';
export const ROCKET_CASE_STUDY_SECTION_INTERVIEWS_LABEL = 'Research Analysis';
export const ROCKET_CASE_STUDY_SECTION_INTERVIEWS_METRIC_PARTICIPANTS_NUMBER = '15';
export const ROCKET_CASE_STUDY_SECTION_INTERVIEWS_METRIC_PARTICIPANTS_LABEL = 'Participants';
export const ROCKET_CASE_STUDY_SECTION_INTERVIEWS_METRIC_ISSUES_NUMBER = '3';
export const ROCKET_CASE_STUDY_SECTION_INTERVIEWS_METRIC_ISSUES_LABEL = 'Main Issues';
export const ROCKET_CASE_STUDY_SECTION_INTERVIEWS_CONTENT = 'Reports of previous research revealed key information.';

export const ROCKET_CASE_STUDY_SECTION_INTERVIEWS_QUOTES_OVERLOAD_TITLE = 'Information Overload';
export const ROCKET_CASE_STUDY_SECTION_INTERVIEWS_QUOTES_OVERLOAD_SUBTITLE = 'Too much information is overwhelming for the users';
export const ROCKET_CASE_STUDY_SECTION_INTERVIEWS_QUOTES_OVERLOAD_QUOTE = 'I am overwhelmed and stressed by the tasks';

export const ROCKET_CASE_STUDY_SECTION_INTERVIEWS_QUOTES_CLARITY_TITLE = 'Lack of Deadline Clarity';
export const ROCKET_CASE_STUDY_SECTION_INTERVIEWS_QUOTES_CLARITY_SUBTITLE = 'Clarity on task deadlines is an important decision factor';
export const ROCKET_CASE_STUDY_SECTION_INTERVIEWS_QUOTES_CLARITY_QUOTE = 'It is not clear...when is this due?';

export const ROCKET_CASE_STUDY_SECTION_INTERVIEWS_QUOTES_DIRECTION_TITLE = 'Lack of Direction';
export const ROCKET_CASE_STUDY_SECTION_INTERVIEWS_QUOTES_DIRECTION_SUBTITLE = 'Users desire a direction to proceed';
export const ROCKET_CASE_STUDY_SECTION_INTERVIEWS_QUOTES_DIRECTION_QUOTE = 'I don’t know where to start';

export const ROCKET_CASE_STUDY_SECTION_AUDIT_TITLE = 'Analyzing the current design';
export const ROCKET_CASE_STUDY_SECTION_AUDIT_LABEL = 'Design Audit';
export const ROCKET_CASE_STUDY_SECTION_AUDIT_ALT = 'An illustration of the current tasks page in Rocket Mortgage Origination with annotations of UX problems identified by conducting UX audit';

export const ROCKET_CASE_STUDY_SECTION_USER_GOALS_TITLE = 'What do the users need?';
export const ROCKET_CASE_STUDY_SECTION_USER_GOALS_LABEL = 'User Goals';
export const ROCKET_CASE_STUDY_SECTION_BUSINESS_GOALS_TITLE = 'What does the business need?';
export const ROCKET_CASE_STUDY_SECTION_BUSINESS_GOALS_LABEL = 'Business Goals';
export const ROCKET_CASE_STUDY_SECTION_GOALS_USER_GOAL_TITLE1 = 'Priority tasks';
export const ROCKET_CASE_STUDY_SECTION_GOALS_USER_GOAL_SUBTITLE1 = 'To understand which tasks are critical and need to be completed immediately';
export const ROCKET_CASE_STUDY_SECTION_GOALS_USER_GOAL_TITLE2 = 'Action plan';
export const ROCKET_CASE_STUDY_SECTION_GOALS_USER_GOAL_SUBTITLE2 = 'To find a clear direction to choose and complete those tasks';
export const ROCKET_CASE_STUDY_SECTION_GOALS_BUSINESS_GOAL_TITLE = 'Fast closures';
export const ROCKET_CASE_STUDY_SECTION_GOALS_BUSINESS_GOAL_SUBTITLE = 'Decrease time-to-close for users';

export const ROCKET_CASE_STUDY_SECTION_OBJECTIVES_TITLE = 'Establishing design principles';
export const ROCKET_CASE_STUDY_SECTION_OBJECTIVES_LABEL = 'Design Objectives';
export const ROCKET_CASE_STUDY_SECTION_OBJECTIVES_TITLE1 = '01';
export const ROCKET_CASE_STUDY_SECTION_OBJECTIVES_SUBTITLE1 = 'Reduce clutter and display only necessary information';
export const ROCKET_CASE_STUDY_SECTION_OBJECTIVES_TITLE2 = '02';
export const ROCKET_CASE_STUDY_SECTION_OBJECTIVES_SUBTITLE2 = 'Provide a clear direction for the users to proceed';

export const ROCKET_CASE_STUDY_SECTION_EXPLORATIONS_TITLE = 'Exploring strategies for redesign';
export const ROCKET_CASE_STUDY_SECTION_EXPLORATIONS_LABEL = 'Explorations';
export const ROCKET_CASE_STUDY_SECTION_EXPLORATIONS_CAROUSEL_LABEL = 'Carousel format';
export const ROCKET_CASE_STUDY_SECTION_EXPLORATIONS_CAROUSEL_ALT = 'Image of a wireframe depicting carousel format';
export const ROCKET_CASE_STUDY_SECTION_EXPLORATIONS_TABLE_LABEL = 'Table format';
export const ROCKET_CASE_STUDY_SECTION_EXPLORATIONS_TABLE_ALT = 'Image of a wireframe depicting table format';
export const ROCKET_CASE_STUDY_SECTION_EXPLORATIONS_BOARD_LABEL = 'Board format';
export const ROCKET_CASE_STUDY_SECTION_EXPLORATIONS_BOARD_ALT = 'Image of a wireframe depicting board format';
export const ROCKET_CASE_STUDY_SECTION_EXPLORATIONS_TASK_BASED_LABEL = 'Task based organization';
export const ROCKET_CASE_STUDY_SECTION_EXPLORATIONS_TASK_BASED_ALT = 'Image of a wireframe depicting task based organization';
export const ROCKET_CASE_STUDY_SECTION_EXPLORATIONS_NEWNESS_BASED_LABEL = 'Newness based organization';
export const ROCKET_CASE_STUDY_SECTION_EXPLORATIONS_NEWNESS_BASED_ALT = 'Image of a wireframe depicting newness based organization';
export const ROCKET_CASE_STUDY_SECTION_EXPLORATIONS_OVERVIEW_DETAIL_LABEL = 'Overview-detail format';
export const ROCKET_CASE_STUDY_SECTION_EXPLORATIONS_OVERVIEW_DETAIL_ALT = 'Image of a wireframe depicting overview-detail format';
export const ROCKET_CASE_STUDY_SECTION_EXPLORATIONS_ALT = 'An illustration of six wireframes showing different explorations tried for redesigning the To-do Page';
export const ROCKET_CASE_STUDY_SECTION_EXPLORATIONS_CONTENT = 'I took the initiative to get feedback and critically discuss the ideas with the team. We discovered that these explorations:';
export const ROCKET_CASE_STUDY_SECTION_EXPLORATIONS_CONTENT_SUBTITLE1 = 'Changed the organization of information';
export const ROCKET_CASE_STUDY_SECTION_EXPLORATIONS_CONTENT_SUBTITLE2 = 'Changed the visual representation of information';
export const ROCKET_CASE_STUDY_SECTION_EXPLORATIONS_CONTENT_SUBTITLE3 = 'Did not provide a way to identify the critical tasks';

export const ROCKET_CASE_STUDY_SECTION_PROPOSED_TITLE = 'Proposed Solution';
export const ROCKET_CASE_STUDY_SECTION_PROPOSED_ALT = 'An illustration of the redesigned To-do Page with annotations highlighting key improvements';
export const ROCKET_CASE_STUDY_SECTION_PROPOSED_FLOW_TITLE = 'Task Flow';
export const ROCKET_CASE_STUDY_SECTION_PROPOSED_FLOW_SUBTITLE = 'What happens when users click on “Start Priority Tasks”?';
export const ROCKET_CASE_STUDY_SECTION_PROPOSED_FLOW_ALT = 'A flow chart representing new proposed task flow';
export const ROCKET_CASE_STUDY_SECTION_PROPOSED_CONSIDERATIONS_TITLE = 'Other UX Considerations';
export const ROCKET_CASE_STUDY_SECTION_PROPOSED_CONSIDERATIONS_SUBTITLE = 'To ensure a complete experience';
export const ROCKET_CASE_STUDY_SECTION_PROPOSED_CONSIDERATIONS_ALT = 'An illustration of the redesigned To-do Page with Co-borrowers tasks tab selected and annotations of improvements';
export const ROCKET_CASE_STUDY_SECTION_PROPOSED_MOBILE_DESIGNS_ALT = 'An illustration of the redesigned To-do Page on a mobile device with annotations of improvements';

export const ROCKET_CASE_STUDY_SECTION_NEXT_TITLE = 'Intended Next Steps';
export const ROCKET_CASE_STUDY_SECTION_NEXT_BULLET_NUMBER1 = '01';
export const ROCKET_CASE_STUDY_SECTION_NEXT_BULLET_SUBTITLE1 = 'Usability test to validate design decisions';
export const ROCKET_CASE_STUDY_SECTION_NEXT_BULLET_NUMBER2 = '02';
export const ROCKET_CASE_STUDY_SECTION_NEXT_BULLET_SUBTITLE2 = 'Experiment with logic for sorting tasks and task flow';
export const ROCKET_CASE_STUDY_SECTION_NEXT_BULLET_NUMBER3 = '03';
export const ROCKET_CASE_STUDY_SECTION_NEXT_BULLET_SUBTITLE3 = 'Measuring success by tracking a combination of metrics';
export const ROCKET_CASE_STUDY_SECTION_NEXT_BULLET_BODY31 = '• Time-to-close';
export const ROCKET_CASE_STUDY_SECTION_NEXT_BULLET_BODY32 = '• Net Promoter Score';
export const ROCKET_CASE_STUDY_SECTION_NEXT_BULLET_BODY33 = '• Number of users missing priority tasks';
export const ROCKET_CASE_STUDY_SECTION_NEXT_BULLET_BODY34 = '• Number of clicks on the primary button';

export const ROCKET_CASE_STUDY_SECTION_LEARNINGS_TITLE = 'Learnings';

export const ROCKET_CASE_STUDY_SECTION_LEARNINGS_BULLET_NUMBER1 = '01';
export const ROCKET_CASE_STUDY_SECTION_LEARNINGS_BULLET_TITLE1 = 'No question is too dumb to ask';
export const ROCKET_CASE_STUDY_SECTION_LEARNINGS_BULLET_SUBTITLE1 = 'Asking questions not only enabled me to raise my level of awareness but also helped me understand the context of my work within the larger process.';

export const ROCKET_CASE_STUDY_SECTION_LEARNINGS_BULLET_NUMBER2 = '02';
export const ROCKET_CASE_STUDY_SECTION_LEARNINGS_BULLET_TITLE2 = 'Communication is the key to effective collaboration';
export const ROCKET_CASE_STUDY_SECTION_LEARNINGS_BULLET_SUBTITLE2 = 'Consistent communication with all collaborators brought everyone on the same page for a shared understanding and made them felt included in the process.';

export const ROCKET_CASE_STUDY_SECTION_LEARNINGS_BULLET_NUMBER3 = '03';
export const ROCKET_CASE_STUDY_SECTION_LEARNINGS_BULLET_TITLE3 = 'Where there is a will there is a way';
export const ROCKET_CASE_STUDY_SECTION_LEARNINGS_BULLET_SUBTITLE3 = 'Constantly pushing to find new ways to get data resulted in discovery of prior research which formed the basis of data driven design decisions.';





