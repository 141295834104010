import './UserQuote.css';

const UserQuote = ({ quote, name = '', role = '', image, alt_text = '' }) => {

    var userDiv;
    if (name || role) {
        userDiv = 
            <div className='flex items-center gap-2 md:gap-4'>
                <img src={image} alt={alt_text} className="w-8 md:w-12 rounded-full"></img>
                <div className='flex flex-col gap-0'>
                    <p className='body dark'>{name}</p>
                    <p className='caption grey'>{role}</p>
                </div>
            </div>;
    }



    return (
        <div className='user-quote-container'>
            <div className='apostrophe'>“</div>
            <div className='quote-outer-container'>
                <div className='quote-container rounded-xl'>
                    <p className='body grey'>{quote}</p>
                    {userDiv}
                </div>
            </div>
        </div>
    );

}

export default UserQuote;