import './Logo.css';
import { Link } from 'react-router-dom';
import { HOMEPAGE_PATH } from '../Strings/Paths';


function Logo() {
  return (
    <Link to={HOMEPAGE_PATH}>
      <div className="logo-container">
        <div className='logo-type'>Shaunak.</div>
      </div>
    </Link>
  );
}

export default Logo;
