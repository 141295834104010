export const ROCKET_INTERNSHIP_BANNER_TITLE = 'Rocket Companies Internship';
export const ROCKET_INTERNSHIP_BANNER_SUBTITLE = 'A summer of designing for the #1 mortgage lender in the United States';
export const ROCKET_INTERNSHIP_BANNER_ALT = 'An illustration of Rocket Mortgage website';

export const ROCKET_INTERNSHIP_OVERVIEW_TITLE1 = 'My Role';
export const ROCKET_INTERNSHIP_OVERVIEW_SUBTITLE1 = 'Experience Designer';

export const ROCKET_INTERNSHIP_OVERVIEW_TITLE2 = 'Team';
export const ROCKET_INTERNSHIP_OVERVIEW_SUBTITLE2 = 'RMO (Rocket Mortgage Origination)';

export const ROCKET_INTERNSHIP_OVERVIEW_TITLE3 = 'Internship Timeline';
export const ROCKET_INTERNSHIP_OVERVIEW_SUBTITLE3 = '11 Weeks · May - Aug 2023';

export const ROCKET_INTERNSHIP_OVERVIEW_TITLE4 = 'Platform';
export const ROCKET_INTERNSHIP_OVERVIEW_SUBTITLE4 = 'Desktop and mobile web';

export const ROCKET_INTERNSHIP_SECTION_TITLE1 = 'Overview';
export const ROCKET_INTERNSHIP_SECTION_CONTENT1 = 'I spent the summer of 2023 working for Rocket Companies as an Experience Design Intern at their main office in Detroit. I got the opportunity to work on one of the most important customer facing product for the company — Rocket Mortgage Origination.';

export const ROCKET_INTERNSHIP_SECTION_TITLE2 = 'What is Rocket Mortgage Origination?';
export const ROCKET_INTERNSHIP_SECTION_CONTENT2 = 'Rocket Mortgage Origination is an authenticated product experience where the users provide documentation and perform tasks to process their mortgage with Rocket. My team specifically worked on the desktop and mobile web platform.';

export const ROCKET_INTERNSHIP_SECTION_TITLE3 = 'Main Case Study';
export const ROCKET_INTERNSHIP_SECTION_CONTENT3 = 'Since my work is under NDA, I cannot share it publicly. If you are a recruiter or a hiring manager, the password for this case study is present in my resume. If you are someone else interested in knowing about my work, please reach out to me.';
export const ROCKET_INTERNSHIP_SECTION_CASE_STUDY_LINK = 'View Case Study';

export const ROCKET_INTERNSHIP_SECTION_LEARNINGS_TITLE = 'Learnings';

export const ROCKET_INTERNSHIP_SECTION_LEARNINGS_BULLET_NUMBER1 = '01';
export const ROCKET_INTERNSHIP_SECTION_LEARNINGS_BULLET_TITLE1 = 'No question is too dumb to ask';
export const ROCKET_INTERNSHIP_SECTION_LEARNINGS_BULLET_SUBTITLE1 = 'Asking questions not only enabled me to raise my level of awareness but also helped me understand the context of my work within the larger process.';

export const ROCKET_INTERNSHIP_SECTION_LEARNINGS_BULLET_NUMBER2 = '02';
export const ROCKET_INTERNSHIP_SECTION_LEARNINGS_BULLET_TITLE2 = 'Communication is the key to effective collaboration';
export const ROCKET_INTERNSHIP_SECTION_LEARNINGS_BULLET_SUBTITLE2 = 'Consistent communication with all collaborators brought everyone on the same page for a shared understanding and made them felt included in the process.';

export const ROCKET_INTERNSHIP_SECTION_LEARNINGS_BULLET_NUMBER3 = '03';
export const ROCKET_INTERNSHIP_SECTION_LEARNINGS_BULLET_TITLE3 = 'Where there is a will there is a way';
export const ROCKET_INTERNSHIP_SECTION_LEARNINGS_BULLET_SUBTITLE3 = 'Constantly pushing to find new ways to get data resulted in discovery of prior research which formed the basis of data driven design decisions.';


export const ROCKET_INTERNSHIP_TESTIMONIALS_TITLE = 'Team Feedback';
export const ROCKET_INTERNSHIP_TESTIMONIAL_TITLE1 = 'Made meaningful contributions';
export const ROCKET_INTERNSHIP_TESTIMONIAL_SUBTITLE1 = "Shaunak has done well in his time as an intern and has made meaningful contributions within the RMO team. He is curious and driven, traits that are especially great to have early in his career. The future is bright for Shaunak and I’m glad he took advantage of all the opportunities to learn and further his craft during his time at Rocket!";
export const ROCKET_INTERNSHIP_TESTIMONIAL_NAME1 = 'Craig Zdanowicz · Director, Experience Design';
export const ROCKET_INTERNSHIP_TESTIMONIAL_POSITION1 = 'My Leader';
export const ROCKET_INTERNSHIP_TESTIMONIAL_ALT1 = 'Picture of Craig Zdanowicz';
export const ROCKET_INTERNSHIP_TESTIMONIAL_TITLE2 = 'Gracefully dealt with multiple constraints';
export const ROCKET_INTERNSHIP_TESTIMONIAL_SUBTITLE2 = "Shaunak hit the ground running in his internship. He methodically learned about a complicated mortgage process in his short time at Rocket. He gracefully dealt with multiple constraints and changing tech requirements. He continually asked thoughtful questions and sought feedback from multiple designers and the Product Manager to make sure his designs met the requirements. He was a pleasure to work with and be his mentor.";
export const ROCKET_INTERNSHIP_TESTIMONIAL_NAME2 = 'Erin Knapp · Sr Experience Designer';
export const ROCKET_INTERNSHIP_TESTIMONIAL_POSITION2 = 'My Mentor';
export const ROCKET_INTERNSHIP_TESTIMONIAL_ALT2 = 'Picture of Erin Knapp';
export const ROCKET_INTERNSHIP_TESTIMONIAL_TITLE3 = 'Standout qualities is his collaborative nature';
export const ROCKET_INTERNSHIP_TESTIMONIAL_SUBTITLE3 = "Throughout his internship with the Design team at Rocket Mortgage, Shaunak consistently demonstrated a remarkable dedication to his work and a strong passion for design. Shaunak's creativity and eye for detail were evident. He delivered high quality design solutions that exceed our expectations. One of Shaunak's standout qualities his collaborative nature. He seamlessly integrated into our design team effectively communicating his ideas. In addition to his technical skills, Shaunak's passions for design and willingness to learn were evident as he consistently sought opportunities to enhance his knowledge and actively participate in team discussions. Based on his performance as a design intern at Rocket Mortgage, I have no doubt Shaunak has a bright future ahead. Any company would be lucky to have him.";
export const ROCKET_INTERNSHIP_TESTIMONIAL_NAME3 = 'Tyler Rowland · Sr Product Manager';
export const ROCKET_INTERNSHIP_TESTIMONIAL_POSITION3 = 'Team Member & Collaborator';
export const ROCKET_INTERNSHIP_TESTIMONIAL_ALT3 = 'Picture of Tyler Rowland';
export const ROCKET_INTERNSHIP_TESTIMONIAL_TITLE4 = 'Active and vocal';
export const ROCKET_INTERNSHIP_TESTIMONIAL_SUBTITLE4 = "From day one, Shaunak was an active and vocal part of our functional team. Focused on design challenges in the mortgage origination space, we had to help users navigate a particularly complex and occasionally frustrating part of the mortgage process. Shaunak not only quickly versed himself in the technical aspects of the experience but soon began applying design principles and helping us craft solutions that would provide clarity and guidance for clients. Even with all the competence and insight he brings, Shaunak always remains flexible and humble. I've rarely seen someone with his experience so clearly ready to work as a design professional.";
export const ROCKET_INTERNSHIP_TESTIMONIAL_NAME4 = 'Alee Karim · Sr UX Writer';
export const ROCKET_INTERNSHIP_TESTIMONIAL_POSITION4 = 'Team Member & Collaborator';
export const ROCKET_INTERNSHIP_TESTIMONIAL_ALT4 = 'Picture of Alee Karim';



