import './Tag.css';

function Tag({label}) {
    if (!label) {
        label = '';
    }
    
    return (
      <div className="tag-container">
        <div className='caption dark'>{label}</div>
      </div>
    );
  }
  
export default Tag;