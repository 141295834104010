import './SectionHeader.css';

function SectionHeader({ title, type, label, invert }) {
  if (!title) {
    title = '';
  }
  if (!type) {
    type = 'short';
  }


  var sectionHeader;
  var sectionLabel;

  if (type === 'wide') {



    if (invert) {
      sectionHeader = (
        <h2 className='light h2'>{title}</h2>
      );
    }
    else {
      sectionHeader = (
        <h2 className='indigo h2'>{title}</h2>
      );
    }
  }
  else {

    if (invert) {
      sectionHeader = (
        <h3 className='light h3'>{title}</h3>
      );
    }
    else {
      sectionHeader = (
        <h3 className='indigo h3'>{title}</h3>
      );
    }
  }

  if (label) {
    if (invert) {
      sectionLabel = (
        <h5 className='light h5'>{label}</h5>
      );
    }
    else {
      sectionLabel = (
        <h5 className='dark h5'>{label}</h5>
      );
    }
  }

  return (
    <div className='flex flex-col gap-4'>

      {sectionLabel}
      <div className="section-header-container">

        {sectionHeader}

      </div>
      <div className='section-header-underline'></div>
    </div>
  );
}

export default SectionHeader;