import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import iconLeftArrow from '../Icons/icon_left_arrow.svg';
import iconRightArrow from '../Icons/icon_right_arrow.svg';
import Testimonial from "./Testimonial";




function NextArrow(props) {
    const { onClick } = props;
    return (
        <div
            className="hidden md:block absolute right-0 top-1/2 cursor-pointer"
            // style={{ display: "block" }}
            onClick={onClick}
        >

            <img src={iconRightArrow} alt='' className="w-12" />

        </div>
    );
}

function PrevArrow(props) {
    const { onClick } = props;
    return (
        <div
            className="hidden md:block absolute left-0 top-1/2 z-40 cursor-pointer "
            // style={{ display: "block" }}
            onClick={onClick}
        >

            <img src={iconLeftArrow} alt='' className="w-12" />

        </div>


    );
}

export default class TestimonialsCarousel extends Component {


    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }
    next() {
        this.slider.slickNext();
    }
    previous() {
        this.slider.slickPrev();
    }



    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: true,
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />
        };

        // console.log(this.props.testimonials_array);
        return (
            <div className="w-full">
                <Slider  {...settings} >


                    {this.props.testimonials_array.map((testimonial, index) => (
                        <div>
                            <Testimonial
                                title={testimonial.title}
                                subtitle={testimonial.subtitle}
                                name={testimonial.name}
                                position={testimonial.position}
                                image={testimonial.image}
                                alt_text={testimonial.alt_text}
                            />
                        </div>

                    ))}


                </Slider>
            </div>
        );
    }
}


