import './BulletPoint.css';
import UnorderedList from './UnorderedList';


const BulletPoint = ({ number, title, subtitle, body, body_padding, emoji }) => {
    if (!number) {
        number = '';
    }
    if (!title) {
        title = '';
    }
    if (!subtitle) {
        subtitle = '';
    }
    if (!body) {
        body = [];
    }

    var titleToRender;
    var bulletPointAlignment = 'bullet-point-container items-center';
    if (title.length) {
        titleToRender = <h6 className='h6 dark'>{title}</h6>;
        bulletPointAlignment = 'bullet-point-container items-start';
    }

    var unorderedList;
    if (body.length) {
        unorderedList = <UnorderedList list={body} left_padding={body_padding} />;
    }

    var numberToRender = (number) ? <h4 className='h4 dark'>{number}</h4> : '';

    var subtitleToRender = (subtitle) ? <p className='body grey'>{subtitle}</p> : '';

    var emojiToRender = (emoji) ? <h3 className='h3 dark'>{emoji}</h3> : '';

    var mainBulletToRender = (number || title || subtitle) ?
        <div className={bulletPointAlignment}>
            {numberToRender}
            <div className='bullet-point-text-container'>
                {emojiToRender}
                {titleToRender}
                {subtitleToRender}
            </div>
        </div>
        : '';


    return (
        <div className='bullet-point-overall-container'>
            {mainBulletToRender}
            {unorderedList}
        </div>
    );
}

export default BulletPoint;