import './PasswordProtectionSheet.css';
import iconClose from '../Icons/icon_close.svg';
import iconLock from '../Icons/icon_lock.svg';
import Button from './Button';
import { useNavigate } from 'react-router-dom';
import * as passwordProtectionStrings from '../Strings/PasswordProtectionStrings';
import InputField from './InputField';
import { PROJECT_ROCKET_CASE_STUDY_PATH } from '../Strings/Paths';


const PasswordProtectionSheet = () => {


    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    };

      
    const validatePassword = () => {


        const passwordInputField = document.getElementById(passwordProtectionStrings.PASSWORD_PROTECTION_INPUT_NAME);
        const password = passwordInputField.value;
        if (password === passwordProtectionStrings.ROCKET_CASE_STUDY_PASSWORD) {
            window.open(PROJECT_ROCKET_CASE_STUDY_PATH, '_self');
        }
        else {
            passwordInputField.value='';
            passwordInputField.style.borderBottom='1px solid red';
        }
    }


    const handleKeyPress = (event) => {
        // look for the `Enter` keyCode
        if (event.keyCode === 13 || event.which === 13) {
          handleSubmit();
        }
      }

      const handleSubmit = () => {
        
        validatePassword();
      }
    
    



    return (


        <div className='bottom-sheet-container'>

            <div className='bottom-sheet-upper-container'>

                <div className='bottom-sheet-controls'>
                    <button onClick={goBack}>
                        <img src={iconClose} className='bottom-sheet-icon' />
                    </button>
                </div>

                <div className='bottom-sheet-heading-container'>
                    <img src={iconLock} className='bottom-sheet-icon' />
                    <h4 className='h4 dark'>{passwordProtectionStrings.PASSWORD_PROTECTION_TITLE}</h4>
                </div>

            </div>

            <div className='bottom-sheet-lower-container'>
                <p className='body grey'>{passwordProtectionStrings.PASSWORD_PROTECTION_INSTRUCTION}</p>

                <div className='bottom-sheet-input-container'>
                    {/* input field */}

                    <InputField 
                        name={passwordProtectionStrings.PASSWORD_PROTECTION_INPUT_NAME}
                        label={passwordProtectionStrings.PASSWORD_PROTECTION_INPUT_LABEL}
                        type='password'
                        placeholder={passwordProtectionStrings.PASSWORD_PROTECTION_INPUT_PLACEHOLDER}
                        handleKeyPress={handleKeyPress}
                    />

                    <Button 
                        label={passwordProtectionStrings.PASSWORD_PROTECTION_BUTTON_LABEL}
                        click_function={validatePassword}
                    />
                </div>
            </div>


        </div>
    );
}

export default PasswordProtectionSheet;