import './VideoComponent.css';

const VideoComponent = ({ video }) => {

    if (!video) {
        video = '';
    }


    return (

        <video className="rounded-3xl video-container" src={video} type='video/mp4' autoPlay loop muted />


    );
}

export default VideoComponent;