import { Link } from "react-router-dom";
import { HOMEPAGE_PATH } from '../Strings/Paths';
import './Tab.css';


const Tab = ({ label, path, active }) => {
    if (!label) {
        label = '';
    }
    if (!path) {
        path = HOMEPAGE_PATH;
    }
    if (!active) {
        active = false;
    }
    
    const tabContainerClass = (active) ? 'tab-active-container' : 'tab-inactive-container';
    return (
        <Link to={path} className="">
            <div className={tabContainerClass}>
                <p className='body dark'>{label}</p>
            </div>
        </Link>
    );
}

export default Tab;