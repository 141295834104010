
import aboutShaunak from '../Images/About/about_shaunak.png';
import * as aboutStrings from '../Strings/AboutStrings';
import TextLink from './TextLink';

import Resume from '../Documents/resume_shaunak_bhanarkar.pdf';
import {CONNECT_LINKEDIN} from '../Strings/GlobalStrings';

const AboutSection = () => {
    return (
        <div className="container w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
            <div className='w-full md:w-4/12'>
                <img src={aboutShaunak} alt={aboutStrings.ABOUT_ALT} className='rounded-3xl'/>
            </div>
            <div className='w-full md:w-8/12 flex flex-col gap-4 md:gap-8'>
                <div className='flex flex-col gap-4'>
                    <div>
                    <h4 className='h6 dark'>{aboutStrings.ABOUT_GREETING}</h4>
                    <h1 className='h2 indigo'>{aboutStrings.ABOUT_INTRO_TITLE}</h1>
                    </div>
                    <p className='body grey'>{aboutStrings.ABOUT_INTRO_CONTENT1}</p>
                    <p className='body grey'>{aboutStrings.ABOUT_INTRO_CONTENT2}</p>
                    <p className='body grey'>{aboutStrings.ABOUT_INTRO_CONTENT3}</p>
                    <p className='body grey'>{aboutStrings.ABOUT_INTRO_CONTENT4}</p>
                </div>
                <div className='w-max flex gap-4'>
                    <TextLink
                        label={aboutStrings.ABOUT_RESUME_LINK_LABEL}
                        path={Resume}
                        target='_blank'
                    />
                    <TextLink
                        label={aboutStrings.ABOUT_LINKEDIN_LINK_LABEL}
                        path={CONNECT_LINKEDIN}
                        target='_blank'
                        outlined={true}
                    />
                </div>
            </div>
        </div>
    );
}

export default AboutSection;