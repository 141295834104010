import { HOMEPAGE_PATH } from '../Strings/Paths';
import './TextLink.css';
import { Link } from 'react-router-dom';

const TextLink = ({ label, path, target, outlined = false }) => {
    if (!label) {
        label = '';
    }
    if (!path) {
        path = HOMEPAGE_PATH;
    }
    if (!target) {
        target = '_self';
    }
    var linkContainerClass = (outlined) ? 'link-container-outlined' : 'link-container';
    var linkTextClass = (outlined) ? 'body indigo' : 'body light';
    return (
        <Link to={path} target={target}>
            <div className={linkContainerClass}>
                <p className={linkTextClass}>{label}</p>
            </div>
        </Link>
    );

}

export default TextLink;
