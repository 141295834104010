import './ProjectsGrid.css';
import ProjectCard from './ProjectCard.js';
import * as homepageStrings from '../Strings/HomepageStrings.js';
import * as paths from '../Strings/Paths.js';
import projectRocketCover from '../Images/Homepage/project_rocket_cover.png';
import projectCrudeCover from '../Images/Homepage/project_crude_cover.png';
import projectOyoCover from '../Images/Homepage/project_oyo_cover.png';
import projectComfortOSCover from '../Images/Homepage/project_comfort_os_cover.png';
import projectHometownHeroesCommunityCover from '../Images/Homepage/project_hometown_heroes_community_cover.png';
import projectHometownHeroesDesignSystem from '../Images/Homepage/project_hometown_heroes_design_system_cover.png';

function ProjectsGridDesignSystem() {




    return (
        <div className='grid grid-cols-1 gap-16 md:grid-cols-2'>

            <ProjectCard
                image={projectHometownHeroesDesignSystem}
                alt_text={homepageStrings.PROJECT_HOMETOWN_HEROES_DESIGN_SYSTEM_ALT}
                tag1_label={homepageStrings.PROJECT_HOMETOWN_HEROES_DESIGN_SYSTEM_TAG1}
                tag2_label={homepageStrings.PROJECT_HOMETOWN_HEROES_DESIGN_SYSTEM_TAG2}
                title={homepageStrings.PROJECT_HOMETOWN_HEROES_DESIGN_SYSTEM_TITLE}
                subtitle={homepageStrings.PROJECT_HOMETOWN_HEROES_DESIGN_SYSTEM_SUBTITLE}
                path={paths.PROJECT_HOMETOWN_HEROES_DESIGN_SYSTEM_PATH}
                size='large'
            />

            



            <ProjectCard
                image={projectCrudeCover}
                alt_text={homepageStrings.PROJECT_CRUDE_ALT}
                // tag1_label={homepageStrings.PROJECT_CRUDE_TAG1}
                tag2_label={homepageStrings.PROJECT_CRUDE_TAG2}
                title={homepageStrings.PROJECT_CRUDE_TITLE}
                subtitle={homepageStrings.PROJECT_CRUDE_SUBTITLE}
                path={paths.PROJECT_CRUDE_PATH}
                size='large'
            />

            

            <ProjectCard
                image={projectComfortOSCover}
                alt_text={homepageStrings.PROJECT_COMFORT_OS_ALT}
                tag1_label={homepageStrings.PROJECT_COMFORT_OS_TAG1}
                tag2_label={homepageStrings.PROJECT_COMFORT_OS_TAG2}
                title={homepageStrings.PROJECT_COMFORT_OS_TITLE}
                subtitle={homepageStrings.PROJECT_COMFORT_OS_SUBTITLE}
                path={paths.PROJECT_COMFORT_OS_PATH}
                size='large'
            />



        </div>
    );

}

export default ProjectsGridDesignSystem;