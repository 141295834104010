

const Picture = ({ image, alt_text, rounded }) => {

    if (!image) {
        image = '';
    }
    if (!alt_text) {
        alt_text = '';
    }
    
    var roundedClass = '';
    if (rounded) {
        roundedClass = 'rounded-3xl';
    }

    return (

        <img src={image} alt={alt_text} className={roundedClass} />

    );
}

export default Picture;