import './ProjectCard.css';
import Tag from './Tag.js';
import { Link } from 'react-router-dom';



function ProjectCard({ image, alt_text, tag1_label, tag2_label, tag3_label, title, subtitle, path, size }) {
    if (!image) {
        image = '';
    }
    if (!alt_text) {
        alt_text = '';
    }
    if (!tag1_label) {
        tag1_label = '';
    }
    if (!tag2_label) {
        tag2_label = '';
    }
    if (!tag3_label) {
        tag3_label = '';
    }
    if (!title) {
        title = '';
    }
    if (!subtitle) {
        subtitle = '';
    }
    if (!path) {
        path = '';
    }
    if (!size) {
        size = 'small';
    }

    var titleClass = 'h6 dark';
    if (size === 'large') {
        titleClass = 'h5 dark';
    }

    var pathValue = new String(path);
    var isPathURL = pathValue.includes("https://");

    const visitExternalSite = () => {
        if (isPathURL)
            window.open(
                path,
                '_blank'
            );
    }

    var tags = [];
    if (tag1_label && tag1_label.length > 0) {
        tags.push(<Tag label={tag1_label} />);
    }
    
    if (tag2_label && tag2_label.length > 0) {
        
        tags.push(<Tag label={tag2_label} />);
    }

    if (tag3_label && tag3_label.length > 0) {
        
        tags.push(<Tag label={tag3_label} />);
    }


    return (

        <div onClick={visitExternalSite}>
            <Link to={isPathURL ? "" : path} >
                <div className='project-card-container'>
                    <img src={image} alt={alt_text} className='rounded-3xl'/>

                    <div className='content-container'>

                        <div className='tags-container'>
                            {tags}

                        </div>

                        <div>
                            <div className={titleClass}>{title}</div>
                            <p className='body grey'>{subtitle}</p>
                        </div>

                    </div>
                </div>
            </Link>

        </div>


    );
}

export default ProjectCard;