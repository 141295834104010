import './Metric.css';

const Metric = ({number, label, emoji}) => {

    return (
        <div className='metric-container'>
            <h3 className='h3 dark'>{emoji}</h3>
            <h3 className='h3-sans dark'>{number}</h3>
            <h6 className='h6 dark'>{label}</h6>
        </div>
    );

}

export default Metric;
