import Header from "../../Components/Header";
import CaseStudyBanner from "../../Components/CaseStudyBanner";
import OverviewSection from '../../Components/OverviewSection';
import rocketInternshipHero from '../../Images/Rocket/rocket_internship_hero.png';
import * as rocketInternshipStrings from '../../Strings/RocketInternshipStrings';
import '../CaseStudy.css';
import ContentSection from "../../Components/ContentSection";
import Footer from "../../Components/Footer";
import { PASSWORD_PROTECTED_PATH } from "../../Strings/Paths";
import photoCraig from '../../Images/Rocket/Testimonials/photo_craig.jpeg';
import photoErin from '../../Images/Rocket/Testimonials/photo_erin.jpeg';
import photoTyler from '../../Images/Rocket/Testimonials/photo_tyler.jpeg';
import photoAlee from '../../Images/Rocket/Testimonials/photo_alee.jpeg';

function Rocket() {



  const rocketInternshipOverviewItems = [
    {
      'title': rocketInternshipStrings.ROCKET_INTERNSHIP_OVERVIEW_TITLE1,
      'subtitle': rocketInternshipStrings.ROCKET_INTERNSHIP_OVERVIEW_SUBTITLE1
    },
    {
      'title': rocketInternshipStrings.ROCKET_INTERNSHIP_OVERVIEW_TITLE2,
      'subtitle': rocketInternshipStrings.ROCKET_INTERNSHIP_OVERVIEW_SUBTITLE2
    },
    {
      'title': rocketInternshipStrings.ROCKET_INTERNSHIP_OVERVIEW_TITLE3,
      'subtitle': rocketInternshipStrings.ROCKET_INTERNSHIP_OVERVIEW_SUBTITLE3
    },
    {
      'title': rocketInternshipStrings.ROCKET_INTERNSHIP_OVERVIEW_TITLE4,
      'subtitle': rocketInternshipStrings.ROCKET_INTERNSHIP_OVERVIEW_SUBTITLE4
    }
  ];






  return (
    <div>
      <Header />

      <div className="page-container">
        <div className="sections-container">

          <div className='hero-container'>
            <CaseStudyBanner
              title={rocketInternshipStrings.ROCKET_INTERNSHIP_BANNER_TITLE}
              subtitle={rocketInternshipStrings.ROCKET_INTERNSHIP_BANNER_SUBTITLE}
              image={rocketInternshipHero}
              alt_text={rocketInternshipStrings.ROCKET_INTERNSHIP_BANNER_ALT}
            />
            <OverviewSection
              item_list={rocketInternshipOverviewItems}
            />


          </div>

          <ContentSection
            title={rocketInternshipStrings.ROCKET_INTERNSHIP_SECTION_TITLE1}
            type='short'
            content_array={[
              {
                'content_type': 'text',
                'text': rocketInternshipStrings.ROCKET_INTERNSHIP_SECTION_CONTENT1
              }
            ]}
          />

          <ContentSection
            title={rocketInternshipStrings.ROCKET_INTERNSHIP_SECTION_TITLE2}
            type='short'
            content_array={[
              {
                'content_type': 'text',
                'text': rocketInternshipStrings.ROCKET_INTERNSHIP_SECTION_CONTENT2
              }
            ]}
          />

          <ContentSection
            title={rocketInternshipStrings.ROCKET_INTERNSHIP_SECTION_TITLE3}
            type='short'
            content_array={[
              {
                'content_type': 'text',
                'text': rocketInternshipStrings.ROCKET_INTERNSHIP_SECTION_CONTENT3
              },
              {
                'content_type': 'link',
                'link_label': rocketInternshipStrings.ROCKET_INTERNSHIP_SECTION_CASE_STUDY_LINK,
                'link_path': PASSWORD_PROTECTED_PATH
              }
            ]}
          />

          <ContentSection
            title={rocketInternshipStrings.ROCKET_INTERNSHIP_SECTION_LEARNINGS_TITLE}
            type='short'
            content_array={[
              {
                'content_type': 'bullet_points',
                'bullet_points': [
                  {
                    'number': rocketInternshipStrings.ROCKET_INTERNSHIP_SECTION_LEARNINGS_BULLET_NUMBER1,
                    'title': rocketInternshipStrings.ROCKET_INTERNSHIP_SECTION_LEARNINGS_BULLET_TITLE1,
                    'subtitle': rocketInternshipStrings.ROCKET_INTERNSHIP_SECTION_LEARNINGS_BULLET_SUBTITLE1
                  },
                  {
                    'number': rocketInternshipStrings.ROCKET_INTERNSHIP_SECTION_LEARNINGS_BULLET_NUMBER2,
                    'title': rocketInternshipStrings.ROCKET_INTERNSHIP_SECTION_LEARNINGS_BULLET_TITLE2,
                    'subtitle': rocketInternshipStrings.ROCKET_INTERNSHIP_SECTION_LEARNINGS_BULLET_SUBTITLE2
                  },
                  {
                    'number': rocketInternshipStrings.ROCKET_INTERNSHIP_SECTION_LEARNINGS_BULLET_NUMBER3,
                    'title': rocketInternshipStrings.ROCKET_INTERNSHIP_SECTION_LEARNINGS_BULLET_TITLE3,
                    'subtitle': rocketInternshipStrings.ROCKET_INTERNSHIP_SECTION_LEARNINGS_BULLET_SUBTITLE3
                  }
                ]
              }
            ]}
          />

          <ContentSection
            title={rocketInternshipStrings.ROCKET_INTERNSHIP_TESTIMONIALS_TITLE}
            type='short'
            content_array={[
              {
                'content_type': 'testimonials',
                'testimonials_array': [
                  {
                    'title': rocketInternshipStrings.ROCKET_INTERNSHIP_TESTIMONIAL_TITLE1,
                    'subtitle': rocketInternshipStrings.ROCKET_INTERNSHIP_TESTIMONIAL_SUBTITLE1,
                    'name': rocketInternshipStrings.ROCKET_INTERNSHIP_TESTIMONIAL_NAME1,
                    'position': rocketInternshipStrings.ROCKET_INTERNSHIP_TESTIMONIAL_POSITION1,
                    'image': photoCraig,
                    'alt_text': rocketInternshipStrings.ROCKET_INTERNSHIP_TESTIMONIAL_ALT1
                  },
                  {
                    'title': rocketInternshipStrings.ROCKET_INTERNSHIP_TESTIMONIAL_TITLE2,
                    'subtitle': rocketInternshipStrings.ROCKET_INTERNSHIP_TESTIMONIAL_SUBTITLE2,
                    'name': rocketInternshipStrings.ROCKET_INTERNSHIP_TESTIMONIAL_NAME2,
                    'position': rocketInternshipStrings.ROCKET_INTERNSHIP_TESTIMONIAL_POSITION2,
                    'image': photoErin,
                    'alt_text': rocketInternshipStrings.ROCKET_INTERNSHIP_TESTIMONIAL_ALT2
                  },
                  {
                    'title': rocketInternshipStrings.ROCKET_INTERNSHIP_TESTIMONIAL_TITLE3,
                    'subtitle': rocketInternshipStrings.ROCKET_INTERNSHIP_TESTIMONIAL_SUBTITLE3,
                    'name': rocketInternshipStrings.ROCKET_INTERNSHIP_TESTIMONIAL_NAME3,
                    'position': rocketInternshipStrings.ROCKET_INTERNSHIP_TESTIMONIAL_POSITION3,
                    'image': photoTyler,
                    'alt_text': rocketInternshipStrings.ROCKET_INTERNSHIP_TESTIMONIAL_ALT3
                  },
                  {
                    'title': rocketInternshipStrings.ROCKET_INTERNSHIP_TESTIMONIAL_TITLE4,
                    'subtitle': rocketInternshipStrings.ROCKET_INTERNSHIP_TESTIMONIAL_SUBTITLE4,
                    'name': rocketInternshipStrings.ROCKET_INTERNSHIP_TESTIMONIAL_NAME4,
                    'position': rocketInternshipStrings.ROCKET_INTERNSHIP_TESTIMONIAL_POSITION4,
                    'image': photoAlee,
                    'alt_text': rocketInternshipStrings.ROCKET_INTERNSHIP_TESTIMONIAL_ALT4
                  }
                ]
              }
            ]}
          />


        </div>

        <Footer />

      </div>
    </div>
  );
}

export default Rocket;
