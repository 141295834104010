import './Footer.css';
import * as globalStrings from '../Strings/GlobalStrings.js'; 
import iconEmail from '../Icons/icon_email.svg'; 
import iconLinkedin from '../Icons/icon_linkedin.svg';
import iconMedium from '../Icons/icon_medium.svg';
import iconFigma from '../Icons/icon_figma.svg';
import iconDribbble from '../Icons/icon_dribbble.svg';

function Footer() {
    return (
      <div className="container my-0 mx-auto footer-container">
        {/* <div className='connect-container'> */}
            {/* <div className='h4 indigo'>{globalStrings.FOOTER_CONNECT_TITLE}</div> */}
            <div className='connect-icons-container'>
                <a href={globalStrings.CONNECT_EMAIL} target='_blank'>
                    <img src={iconEmail} />
                </a>

                <a href={globalStrings.CONNECT_LINKEDIN} target='_blank'>
                    <img src={iconLinkedin} />
                </a>

                <a href={globalStrings.CONNECT_MEDIUM} target='_blank'>
                    <img src={iconMedium} />
                </a>

                <a href={globalStrings.CONNECT_FIGMA} target='_blank'>
                    <img src={iconFigma} />
                </a>

                <a href={globalStrings.CONNECT_DRIBBBLE} target='_blank'>
                    <img src={iconDribbble} />
                </a>
            {/* </div> */}
        </div>
        <div className='caption grey copyright-statement'>{globalStrings.FOOTER_COPYRIGHT_STATEMENT}</div>
      </div>
    );
  }
  
export default Footer;
  