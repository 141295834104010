// banner
export const RATE_PLANS_BANNER_TITLE = 'Rate Plans';
export const RATE_PLANS_BANNER_SUBTITLE = 'Introducing flexible booking options on the leading hotel booking app in India';
export const RATE_PLANS_BANNER_ALT = 'An illustration showcasing revamped room category widget of the Oyo app';

// overview section
export const RATE_PLANS_OVERVIEW_TITLE1 = 'My Role';
export const RATE_PLANS_OVERVIEW_SUBTITLE1 = 'Product Designer';
export const RATE_PLANS_OVERVIEW_TITLE2 = 'Collaborators';
export const RATE_PLANS_OVERVIEW_SUBTITLE2 = 'Design Manager, Product Managers, Business Analysts, Developers';
export const RATE_PLANS_OVERVIEW_TITLE3 = 'Project Timeline';
export const RATE_PLANS_OVERVIEW_SUBTITLE3 = '10 Weeks · Jan - Mar 2022';
export const RATE_PLANS_OVERVIEW_TITLE4 = 'Tools';
export const RATE_PLANS_OVERVIEW_SUBTITLE4 = 'Figma, FigJam, Lookback, Jira';

// content sections
export const RATE_PLANS_SECTION_OVERVIEW_TITLE = 'Overview';
export const RATE_PLANS_SECTION_OVERVIEW_CONTENT1 = 'Oyo is one of the leading hotel booking app in India and across the world.';
export const RATE_PLANS_SECTION_OVERVIEW_METRIC_NUMBER1 = '35';
export const RATE_PLANS_SECTION_OVERVIEW_METRIC_LABEL1 = 'Countries';
export const RATE_PLANS_SECTION_OVERVIEW_METRIC_NUMBER2 = '157k+';
export const RATE_PLANS_SECTION_OVERVIEW_METRIC_LABEL2 = 'Hotels & Homes';
export const RATE_PLANS_SECTION_OVERVIEW_METRIC_NUMBER3 = '50M+';
export const RATE_PLANS_SECTION_OVERVIEW_METRIC_LABEL3 = 'App Downloads';
export const RATE_PLANS_SECTION_OVERVIEW_CONTENT2 = 'This project introduces flexible booking options called ‘Rate Plans’ for users to conveniently choose and customize cancellation policy, payment method, and meals within a limited number of steps.';
export const RATE_PLANS_SECTION_OVERVIEW_ALT = 'An illustration of the home page of the Oyo app';

export const RATE_PLANS_SECTION_CONTRIBUTIONS_TITLE = 'Contributions';
export const RATE_PLANS_SECTION_CONTRIBUTIONS_BULLET_NUMBER1 = '01';
export const RATE_PLANS_SECTION_CONTRIBUTIONS_BULLET_SUBTITLE1 = 'Led the end-to-end design efforts on a high-priority project directly overseen by senior leadership, including the VP of Product.';
export const RATE_PLANS_SECTION_CONTRIBUTIONS_BULLET_NUMBER2 = '02';
export const RATE_PLANS_SECTION_CONTRIBUTIONS_BULLET_SUBTITLE2 = 'Partnered with cross-functional stakeholders to overcome constraints and establish a new design pattern for the solution.';
export const RATE_PLANS_SECTION_CONTRIBUTIONS_BULLET_NUMBER3 = '03';
export const RATE_PLANS_SECTION_CONTRIBUTIONS_BULLET_SUBTITLE3 = 'Performed user testing with 8 participants on various design concepts to inform decisions for the final design.';

export const RATE_PLANS_SECTION_PROBLEM_TITLE = 'Problem';
export const RATE_PLANS_SECTION_PROBLEM_NUMBER1 = '01';
export const RATE_PLANS_SECTION_PROBLEM_TITLE1 = 'Inflexible cancellation policy';
export const RATE_PLANS_SECTION_PROBLEM_SUBTITLE1 = 'No choice of cancellation policy, 45% users willing to pay extra for refundable booking';
export const RATE_PLANS_SECTION_PROBLEM_NUMBER2 = '02';
export const RATE_PLANS_SECTION_PROBLEM_TITLE2 = 'Resistance for online payment';
export const RATE_PLANS_SECTION_PROBLEM_SUBTITLE2 = 'General preference for pay-at-hotel, 25-30% users willing to pay online if offered discount';
export const RATE_PLANS_SECTION_PROBLEM_NUMBER3 = '03';
export const RATE_PLANS_SECTION_PROBLEM_TITLE3 = 'Low opt-in rate for meals compared to competition';
export const RATE_PLANS_SECTION_PROBLEM_SUBTITLE3 = 'Less than 5% users voluntarily opt-in for meals during the hotel booking journey';

export const RATE_PLANS_SECTION_SOLUTION_TITLE = 'Solution';
export const RATE_PLANS_SECTION_SOLUTION_BULLET_TITLE = 'Customizable Rate Plans';
export const RATE_PLANS_SECTION_SOLUTION_BULLET_SUBTITLE = 'Rate plans are integrated into the room category cards, allowing users to select or customize them directly.';
export const RATE_PLANS_SECTION_SOLUTION_TITLE1 = 'Rate plans within the room category card';
export const RATE_PLANS_SECTION_SOLUTION_SUBTITLE1 = 'Three curated rate plans are offered inside each room category card. The preselected rate plan is in expanded state displaying its details.';
export const RATE_PLANS_SECTION_SOLUTION_ALT1 = 'An illustration of the default state of the room category widget on the hotel detail page of the Oyo app';
export const RATE_PLANS_SECTION_SOLUTION_TITLE2 = 'Compare and opt';
export const RATE_PLANS_SECTION_SOLUTION_SUBTITLE2 = 'Other rate plans can be expanded with a click. A user can opt for desired rate plan within a room category based on their preference.';
export const RATE_PLANS_SECTION_SOLUTION_ALT2 = 'An illustration of the room category widget with the second plan in the card in its expanded state';
export const RATE_PLANS_SECTION_SOLUTION_TITLE3 = 'Customize when needed';
export const RATE_PLANS_SECTION_SOLUTION_SUBTITLE3 = 'If a user is not satisfied with the curated rate plans, the customize option provides complete freedom to tweak options.';
export const RATE_PLANS_SECTION_SOLUTION_ALT3 = 'An illustration of the room category widget with the selection sheet open';

export const RATE_PLANS_SECTION_IMPACT_TITLE = 'Impact';
export const RATE_PLANS_SECTION_IMPACT_METRIC_NUMBER = '20% increase';
export const RATE_PLANS_SECTION_IMPACT_LABEL = 'in CxR (Conversion x Realization)';
export const RATE_PLANS_SECTION_IMPACT_CONTENT = 'CxR represents the proportion of users who book online (conversion) versus those who actually check-in at the hotel (realization).';

export const RATE_PLANS_SECTION_PROCESS_TITLE = 'The Process';

export const RATE_PLANS_SECTION_ANALYSIS_LABEL = 'Competitive Analysis';
export const RATE_PLANS_SECTION_ANALYSIS_TITLE = 'What do other platforms do';
export const RATE_PLANS_SECTION_ANALYSIS_ALT = 'An illustration showcasing screenshots of different competitor apps highlighting key points of interest';
export const RATE_PLANS_SECTION_ANALYSIS_NUMBER1 = '01';
export const RATE_PLANS_SECTION_ANALYSIS_TITLE1 = 'Rate plans';
export const RATE_PLANS_SECTION_ANALYSIS_SUBTITLE1 = 'All of the competitors offer choices between group of options';
export const RATE_PLANS_SECTION_ANALYSIS_NUMBER2 = '02';
export const RATE_PLANS_SECTION_ANALYSIS_TITLE2 = 'Dedicated additional step';
export const RATE_PLANS_SECTION_ANALYSIS_SUBTITLE2 = 'Most of the competitors have a mandatory dedicated step to choose plans';
export const RATE_PLANS_SECTION_ANALYSIS_NUMBER3 = '03';
export const RATE_PLANS_SECTION_ANALYSIS_TITLE3 = 'Summary/checkout page';
export const RATE_PLANS_SECTION_ANALYSIS_SUBTITLE3 = 'All of the competitors have a checkout page to review booking information';

export const RATE_PLANS_SECTION_CONSTRAINT_TITLE = '3-step Booking Constraint';
export const RATE_PLANS_SECTION_CONSTRAINT_ALT = 'An illustration of the 3-step booking process on the Oyo app';
export const RATE_PLANS_SECTION_CONSTRAINT_TITLE1 = 'No additional mandatory step in the booking journey';
export const RATE_PLANS_SECTION_CONSTRAINT_SUBTITLE1 = 'Oyo is known for its unique 3-step booking journey, where users can book a hotel in just 3 clicks. To adhere with this, introduction of any additional mandatory step was not allowed.';

export const RATE_PLANS_SECTION_USER_GOALS_LABEL = 'User Goals';
export const RATE_PLANS_SECTION_USER_GOALS_TITLE = 'What do the users need?';
export const RATE_PLANS_SECTION_GOALS_TITLE1 = 'Freedom of choice';
export const RATE_PLANS_SECTION_GOALS_SUBTITLE1 = 'To be able to choose booking options based on their preference';
export const RATE_PLANS_SECTION_BUSINESS_GOALS_LABEL = 'Business Goals';
export const RATE_PLANS_SECTION_BUSINESS_GOALS_TITLE = 'What does the business need?';
export const RATE_PLANS_SECTION_GOALS_TITLE2 = 'Increase conversion';
export const RATE_PLANS_SECTION_GOALS_SUBTITLE2 = 'Increase the number of users realizing their booking (checking in at hotel)';
export const RATE_PLANS_SECTION_GOALS_TITLE3 = 'Capture revenue';
export const RATE_PLANS_SECTION_GOALS_SUBTITLE3 = 'Capture the potential increase in revenue while maintaining 3-step booking';

export const RATE_PLANS_SECTION_VARIANTS_LABEL = 'Design Explorations';
export const RATE_PLANS_SECTION_VARIANTS_TITLE = 'Exploring 3 approaches';
export const RATE_PLANS_SECTION_VARIANTS_LABEL_X = 'Variant X';
export const RATE_PLANS_SECTION_VARIANTS_TITLE_X = 'Focus on customization';
export const RATE_PLANS_SECTION_VARIANTS_LABEL_Y = 'Variant Y';
export const RATE_PLANS_SECTION_VARIANTS_TITLE_Y = 'Focus on choice';
export const RATE_PLANS_SECTION_VARIANTS_LABEL_Z = 'Variant Z';
export const RATE_PLANS_SECTION_VARIANTS_TITLE_Z = 'Hybrid approach';
export const RATE_PLANS_SECTION_VARIANTS_ALT_X = 'An illustration of the design variant X of the room category widget on the Oyo app with annotations highlighting its key features';
export const RATE_PLANS_SECTION_VARIANTS_ALT_Y = 'An illustration of the design variant Y of the room category widget on the Oyo app with annotations highlighting its key features';
export const RATE_PLANS_SECTION_VARIANTS_ALT_Z = 'An illustration of the design variant Z of the room category widget on the Oyo app with annotations highlighting its key features';

export const RATE_PLANS_SECTION_TESTING_TITLE = 'User Testing';
export const RATE_PLANS_SECTION_TESTING_CONTENT = 'To settle the varied opinions of different stakeholders and decide between the design variants, we conducted remote moderated usability testing using Lookback.';
export const RATE_PLANS_SECTION_TESTING_METRIC_NUMBER1 = '8';
export const RATE_PLANS_SECTION_TESTING_METRIC_LABEL1 = 'Representative users';
export const RATE_PLANS_SECTION_TESTING_METRIC_NUMBER2 = '2';
export const RATE_PLANS_SECTION_TESTING_METRIC_LABEL2 = 'Variants per user';
export const RATE_PLANS_SECTION_TESTING_METRIC_NUMBER3 = '2';
export const RATE_PLANS_SECTION_TESTING_METRIC_LABEL3 = 'Tasks per user';
export const RATE_PLANS_SECTION_TESTING_ALT = 'A donut chart representing the results of the user testing activity, highlighting that the variant Z was the most preferred (by 4 users)';
export const RATE_PLANS_SECTION_TESTING_TITLE1 = 'Why was Variant Z most preferred?';
export const RATE_PLANS_SECTION_TESTING_SUBTITLE1 = 'Variant Z makes it easier to compare plans and make changes, if required.';

export const RATE_PLANS_SECTION_FINAL_DESIGN_TITLE = 'Final design';
export const RATE_PLANS_SECTION_FINAL_DESIGN_ALT = 'Screenshots of the final design depicting the flow and functionality of the roomc category widget and rate plans';

export const RATE_PLANS_SECTION_NEXT_STEPS_TITLE = 'Intended Next Steps';
export const RATE_PLANS_SECTION_NEXT_STEPS_BULLET_NUMBER1 = '01';
export const RATE_PLANS_SECTION_NEXT_STEPS_BULLET_SUBTITLE1 = 'MVP in development for pilot program';
export const RATE_PLANS_SECTION_NEXT_STEPS_BULLET_NUMBER2 = '02';
export const RATE_PLANS_SECTION_NEXT_STEPS_BULLET_SUBTITLE2 = 'A/B testing for a couple of months in a phased rollout';
export const RATE_PLANS_SECTION_NEXT_STEPS_BULLET_NUMBER3 = '03';
export const RATE_PLANS_SECTION_NEXT_STEPS_BULLET_SUBTITLE3 = 'Success metric: CxR (conversion x realization)';
export const RATE_PLANS_SECTION_NEXT_STEPS_BULLET_NUMBER4 = '04';
export const RATE_PLANS_SECTION_NEXT_STEPS_BULLET_SUBTITLE4 = 'Anticipated 15-20% increase in CxR for success';

export const RATE_PLANS_SECTION_LEARNINGS_TITLE = 'Learnings';
export const RATE_PLANS_SECTION_LEARNINGS_BULLET_NUMBER1 = '01';
export const RATE_PLANS_SECTION_LEARNINGS_BULLET_SUBTITLE1 = 'Understanding business goals is as important as user goals.';
export const RATE_PLANS_SECTION_LEARNINGS_BULLET_NUMBER2 = '02';
export const RATE_PLANS_SECTION_LEARNINGS_BULLET_SUBTITLE2 = 'Communication between different stakeholders is critical.';
export const RATE_PLANS_SECTION_LEARNINGS_BULLET_NUMBER3 = '03';
export const RATE_PLANS_SECTION_LEARNINGS_BULLET_SUBTITLE3 = 'Good design has to pave its way through compromises.';

export const RATE_PLANS_SECTION_WHAT_IF_TITLE = 'What if there was no constraint?';
export const RATE_PLANS_SECTION_WHAT_IF_CONTENT = 'If additional anticipated steps could be added in the user journey, I would have liked to try these:';
export const RATE_PLANS_SECTION_WHAT_IF_BULLET_NUMBER1 = '01';
export const RATE_PLANS_SECTION_WHAT_IF_TITLE1 = 'New step for rate plans';
export const RATE_PLANS_SECTION_WHAT_IF_SUBTITLE1 = 'A dedicated mandatory step after hotel detail page to choose plan';
export const RATE_PLANS_SECTION_WHAT_IF_BULLET_NUMBER2 = '02';
export const RATE_PLANS_SECTION_WHAT_IF_TITLE2 = 'Checkout page';
export const RATE_PLANS_SECTION_WHAT_IF_SUBTITLE2 = 'A checkout flow to streamline the complex booking process';
export const RATE_PLANS_SECTION_WHAT_IF_BULLET_NUMBER3 = '03';
export const RATE_PLANS_SECTION_WHAT_IF_TITLE3 = 'Segregated choices';
export const RATE_PLANS_SECTION_WHAT_IF_SUBTITLE3 = 'Allowing users to choose one thing at a time';






